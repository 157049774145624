// noinspection DuplicatedCode

import React, {useState, useEffect, useRef, useCallback} from "react";
import { doc, collection, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from "../firebase/firebase-config";
import {getStorage, ref as storageRef, getDownloadURL, uploadBytesResumable} from 'firebase/storage';
import useProducts from './useProducts';
import { spiral } from 'ldrs'
import algoliasearch from "algoliasearch/lite";
import {Store} from "react-notifications-component";
import {ConfirmDialog} from "primereact/confirmdialog";
const AddProduct = () => {
    const client = algoliasearch('THF4U85QG9', '051ed3cc110e37b5d97170f72e97e936');
    const index = client.initIndex('products');
    spiral.register("spiral-loader")
    const [searchTerm, setSearchTerm] = useState('');
    const productNameRef = useRef(null);
    const topOfPageRef = useRef(null);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [productz, setProduct] = useState(null)
    const [productName, setProductName] = useState('');
    const [productBrand, setProductBrand] = useState('');
    const [productBarcode, setProductBarcode] = useState('');
    const [costPrice, setCostPrice] = useState('');
    const [retailPrice, setRetailPrice] = useState('');
    const [wholesalePrice, setWholesalePrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [productImage, setProductImage] = useState('');
    const [editedProductImage, setEditedProductImage] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isEditFormVisible, setIsEditFormVisible] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const { products, isLoading, canLoadMore,
        fetchProducts, searchProducts, removeProduct } = useProducts(itemsPerPage); // Assuming 5 items per initial load


    const storage = getStorage();
    const productBarcodeRef = useRef(null);
    const quantityRef = useRef(null);
    const editingProductBarcodeRef = useRef(null);
    const editingProductQuantityRef = useRef(null);
    const isAccept = useState(true);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        fetchProducts(true); // Initial fetch, 'true' to reset pagination
    }, []);


    function debounce(func, wait) {
        let timeout;
        return {
            call: function(...args) {
                clearTimeout(timeout);
                timeout = setTimeout(() => func(...args), wait);
            },
            cancel: function() {
                clearTimeout(timeout);
            }
        };
    }

    const debouncedSearch = useCallback(debounce((nextSearchTerm) => {
        if (nextSearchTerm.trim() !== '') {
            searchProducts(nextSearchTerm);
        } else {
            fetchProducts(true);
        }
    }, 500), []); // Dependency array left empty to ensure debounce function does not reset

    useEffect(() => {
        debouncedSearch.call(searchTerm);

        // Cleanup function to cancel the debounce if the component unmounts or if the effect re-runs
        return () => debouncedSearch.cancel();
    }, [searchTerm, debouncedSearch]);

    // Handler for search input change
    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    // const handleImageChange = (e) => {
    //
    //     const file = e.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setProductImage(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleEnterKey = (event, nextFieldRef) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submit on Enter press
            nextFieldRef.current.focus(); // Move focus to the next field
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileRef = storageRef(storage, `products/${file.name}`);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    setUploadStatus('%' + progress.toFixed(2) + 'تم تحميل');
                },
                (error) => {
                    console.error("Error uploading file:", error);
                    setUploadStatus('Upload failed: ' + error.message);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        // Here you can call a function to update the parent state, or Redux store, etc.
                        setUploadStatus('تم تحميل الصورة');
                        setProductImage(downloadURL);
                    });
                }
            );
        }
    };

    const handleImageUpdate = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a reference to the location where you want to upload the file in GCS
            const fileRef = storageRef(storage, `products/${file.name}`);

            // Use `uploadBytesResumable` for resumable uploads and progress monitoring
            const uploadTask = uploadBytesResumable(fileRef, file);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get upload progress as a percentage
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    setUploadStatus('%' + progress.toFixed(2) + 'تم تحميل');
                    console.log('Upload is ' + progress + '% done');

                    switch (snapshot.state) {
                        case 'paused': // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case 'running': // or 'running'
                            console.log('Upload is running');
                            break;

                        default:
                            console.log('No case.')
                    }
                },
                (error) => {
                    // Handle unsuccessful uploads
                    console.error("Error uploading file:", error);
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        // Use the URL from storage in your application instead of the base64 string
                        setEditedProductImage(downloadURL);
                        setUploadStatus('تم تحميل الصورة!');
                    });
                }
            );
        }
    };


    const handleEditClick = (product) => {
        setEditingProduct(product);
        setIsEditFormVisible(true); // Assuming you have a state to toggle visibility
    };



    const currentProducts = products
        .filter((product) =>
            product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.productBarcode.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )

    const handleProductAdding = async (e) => {
        e.preventDefault();

        const searchResult = await index.search('', {
            filters: `productBarcode=${productBarcode}`, // Assuming productBarcode is a numeric value in Algolia
            attributesToRetrieve: ['productBarcode'],
            hitsPerPage: 1,
        });

        if (searchResult.hits.length > 0) {
            // alert('الباركود مكرر! لا يمكن اضافة المنتج');
            Store.addNotification({
                title: "خطأ",
                message: "الباركود مكرر! لا يمكن اضافة المنتج.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});
            return;
        }

        try {
            const docRef = await addDoc(collection(db, 'products'), {
                productName: productName +" "+productBrand,
                productBarcode: Number(productBarcode),
                quantity: Number(quantity),
                costPrice: Number(costPrice),
                retailPrice: Number(retailPrice),
                wholesalePrice: Number(wholesalePrice),
                productImage,
            });
            // alert('تم اضافة المنتج بنجاح');
            Store.addNotification({
                title: "تمت العملية",
                message: `تمت اضافة المنتج ${productName} بنجاح`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});
            setProductName("")
            setProductBrand("")
            setProductBarcode(0)
            setQuantity(0)
            setCostPrice(0)
            setRetailPrice(0)
            setWholesalePrice(0)
            setProductImage("")
            handleFocusAndScroll();
        } catch (error) {
            console.error('خطأ في اضافة المنتج..', error);
            // alert('خطأ في اضافة المنتج');
            Store.addNotification({
                title: "خطأ",
                message: "خطأ في اضافة المنتج",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});
        }
    };



    // const handleProductUpdate = async (e) => {
    //     e.preventDefault();
    //     if (editingProduct && editingProduct.id) {
    //         const productRef = doc(db, "products", editingProduct.id);
    //
    //         // Prepare the product data for updating
    //         const updatedProductData = {
    //             productName: editingProduct.productName,
    //             productBarcode: Number(editingProduct.productBarcode), // Ensure number type
    //             retailPrice: Number(editingProduct.retailPrice), // Ensure number type
    //             wholesalePrice: Number(editingProduct.wholesalePrice), // Ensure number type
    //             quantity: Number(editingProduct.quantity), // Ensure number type
    //             productImage: editedProductImage ? editedProductImage : editingProduct.productImage
    //         };
    //
    //         try {
    //             await updateDoc(productRef, updatedProductData);
    //             alert('تم تحديث بيانات المنتج!');
    //             // Hide the edit form and clear the editing product
    //             setIsEditFormVisible(false);
    //             setEditingProduct(null);
    //             const fetchProducts = async () => {
    //                 const querySnapshot = await getDocs(collection(db, "products"));
    //                 const productsArray = [];
    //                 querySnapshot.forEach((doc) => {
    //                     productsArray.push({ id: doc.id, ...doc.data() });
    //                 });
    //                 setProducts(productsArray);
    //             };
    //             fetchProducts();
    //         } catch (error) {
    //             console.error("خطأ في تحديث بيانات المنتج", error);
    //             alert("خطأ في تحديث بيانات المنتج");
    //         }
    //     } else {
    //         alert("خطأ في تحديث بيانات المنتج: يرجى اختيار منتج!");
    //     }
    // };
    const handleProductUpdate = async (e) => {
        e.preventDefault();
        if (editingProduct && editingProduct.id) {
            const productRef = doc(db, "products", editingProduct.id);
            const updatedProductData = {
                productName: editingProduct.productName,
                productBarcode: Number(editingProduct.productBarcode),
                retailPrice: Number(editingProduct.retailPrice),
                wholesalePrice: Number(editingProduct.wholesalePrice),
                quantity: Number(editingProduct.quantity),
                productImage: editedProductImage ? editedProductImage : editingProduct.productImage,
            };

            try {
                await updateDoc(productRef, updatedProductData);
                // alert('تم تحديث بيانات المنتج!');
                Store.addNotification({
                    title: "تمت العملية",
                    message: `تم تحديث بيانات المنتج`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});
                fetchProducts(true);
                setIsEditFormVisible(false);
                setEditingProduct(null);
            } catch (error) {
                console.error("خطأ في تحديث بيانات المنتج", error);
                // alert("خطأ في تحديث بيانات المنتج");
                Store.addNotification({
                    title: "خطأ",
                    message: "خطأ في تحديث بيانات المنتج",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});
            }
        } else {
            // alert("خطأ في تحديث بيانات المنتج: يرجى اختيار منتج!");
            Store.addNotification({
                title: "خطأ",
                message: "خطأ في تحديث البيانات: تحديث منتج غير موجود أو محذوف",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});
        }
    };

    const handleProductDelete = async (productToDelete) => {
        if (isAccept) {
            if (productToDelete && productToDelete.id) {
                try {
                    const productRef = doc(db, "products", productToDelete.id);
                    await deleteDoc(productRef);
                    // alert("تم حذف المنتج بنجاح!");
                    Store.addNotification({
                        title: "حذف منتج",
                        message: `تم حذف المنتج ${productToDelete.productName} بنجاح`,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }});
                    // setProducts(prevProducts => prevProducts.filter(product => product.id !== productToDelete.id));
                    // Additional cleanup if necessary, e.g., close modal or clear selected product
                    if (editingProduct && editingProduct.id === productToDelete.id) {
                        setEditingProduct(null); // Clear the currently editing product if it's the one being deleted
                        setIsEditFormVisible(false); // Optionally close the edit form
                    }
                removeProduct(productToDelete.id)
                } catch (error) {
                    // console.error("حدث خطأ أثناء حذف المنتج", error);
                    // alert("خطأ في حذف المنتج");
                    Store.addNotification({
                        title: "خطأ",
                        message: "خطأ في حذف المنتج",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }});
                }
            } else {
                Store.addNotification({
                    title: "تحذير",
                    message: "يرجى اختيار منتج لحذفه",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});
            }
        } else {
            // If the user did not type "delete", notify them that the deletion has been canceled
            // alert("تم إلغاء عملية الحذف.");
            console.log("Delete operation has been cancelled.");
        }
    };

    const removeLeadingZero = (value) => {
        // Handle cases where value is null or undefined
        if (!value) return "";
        return value.toString().replace(/^0+/, "");
    };

    const handleSelectChange = (event) => {
        setItemsPerPage(Number(event.target.value));
    };

    const handleFocusAndScroll = () => {
        topOfPageRef.current.scrollIntoView({ behavior: "smooth" }) // Scroll to the top of the page
        productNameRef.current.focus(); // Set focus on the first input field
    };


    function generateBarcode() {
        const now = new Date();
        // Format the timestamp to 'YYMMDDHHMMSSS'
        const timestamp = `${now.getFullYear().toString().slice(2)}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}${now.getMilliseconds().toString().padStart(3, '0')}`;
        // Normally, the last digit of an EAN13 barcode is a checksum, but here we'll assume the given task simplifies
        // this requirement.
        // Make sure the barcode has exactly 13 digits (you might need to adjust logic here)
        let barcode = timestamp.slice(0, 12); // Taking first 12 digits
        barcode += calculateEAN13Checksum(barcode); // Append checksum digit, which is a simple placeholder function here
        setProductBarcode(barcode);
    }

    function calculateEAN13Checksum(barcode) {
        const digits = barcode.split('').map(Number);
        const checksum = digits.reduce((sum, digit, index) => {
            return sum + (digit * (index % 2 === 0 ? 1 : 3));
        }, 0);
        return (10 - (checksum % 10)) % 10;
    }

    const loadMoreProducts = async () => {
        if (searchTerm) {
            await searchProducts(searchTerm, true);
        } else {
            await fetchProducts(false);
        }
    };

    document.body.style.backgroundColor = 'white'
    return(
        <div className='signup-container'>
            <ConfirmDialog  style={{width: "auto"}}
                           visible={visible} onHide={() => setVisible(false)}
                           message="هل أنت متأكد حذف هذا المنتج؟"
                           header="تأكيد الحذف"
                           acceptLabel="نعم"
                           rejectLabel="لا"
                           accept={() => handleProductDelete(productz)}
                           reject={() => console.log("yes")}
            />
            {/* Button to toggle form visibility */}
            <div className='edit-product-buttons'>
                <button id='login-button' onClick={() => {
                    setIsEditFormVisible(!isEditFormVisible);
                    if (isFormVisible) setIsFormVisible(false)
                    setUploadStatus('')
                    setUploadProgress(0);
                }}>
                    {isEditFormVisible ? 'اخفاء قائمة تعديل المنتج' : 'تعديل/حذف منتج'}
                </button>
                <button id='login-button' onClick={() => {
                    setIsFormVisible(!isFormVisible);
                    if (isEditFormVisible) setIsEditFormVisible(false)
                    setUploadStatus('')
                    setUploadProgress(0);
                }}>
                    {isFormVisible ? 'اخفاء قائمة اضافة المنتج' : 'إضافة منتج'}
                </button>

            </div>


            {/* Conditional rendering based on isFormVisible state */}
            {isFormVisible && (
                <form id='signInForm' onSubmit={handleProductAdding}>
                    <h2 ref={topOfPageRef}>يرجى اضافة معلومات المنتج</h2>
                    <label htmlFor="input-field">إسم المنتج</label>
                    <input
                        className='input-field'
                        id="input-field"
                        type='text'
                        placeholder='مثال: منشار خشب'
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        ref={productNameRef}
                    ></input>
                    <label htmlFor="input-field">الماركة</label>
                    <input
                        className='input-field'
                        id="input-field"
                        type='text'
                        placeholder='مثال: وادفو/سايبس'
                        value={productBrand}
                        onChange={(e) => setProductBrand(e.target.value)}
                    ></input>
                    <label htmlFor="input-field">رقم المنتج (الباركود)</label>
                    <input
                        className='input-field'
                        type='number'
                        placeholder='مثال: 2404142149135'
                        value={removeLeadingZero(productBarcode)}
                        onChange={(e) => setProductBarcode(e.target.value)}
                        onKeyDown={(e) => handleEnterKey(e, quantityRef)} // Use the handler here
                        ref={productBarcodeRef} // Assign the ref
                    ></input>
                    <button
                        type="button"
                        onClick={generateBarcode}>توليد الباركود
                    </button>
                    <label htmlFor="input-field">الكمية الموجودة</label>
                    <input
                        className='input-field'
                        type='number'
                        placeholder='مثال: 10'
                        value={removeLeadingZero(quantity)}
                        onChange={(e) => setQuantity(e.target.value)}
                        ref={quantityRef}
                    ></input>
                    <label htmlFor="input-field">سعر التكلفة (شيكل)</label>
                    <input
                        className='input-field'
                        type='number'
                        placeholder='ادخل سعر التكلفة'
                        value={removeLeadingZero(costPrice)}
                        onChange={(e) => setCostPrice(e.target.value)}
                    ></input>
                    <label htmlFor="input-field">سعر التجزئة/المفرق (شيكل)</label>
                    <input
                        className='input-field'
                        type='number'
                        placeholder='ادخل سعر التجزئة'
                        value={removeLeadingZero(retailPrice)}
                        onChange={(e) => setRetailPrice(e.target.value)}
                    ></input>
                    <label htmlFor="input-field">سعر الجملة (شيكل)</label>
                    <input
                        className='input-field'
                        type='number'
                        placeholder='ادخل سعر الجملة'
                        value={removeLeadingZero(wholesalePrice)}
                        onChange={(e) => setWholesalePrice(e.target.value)}
                    ></input>
                    <label htmlFor="productImage">صورة المنتج</label>
                    <div>
                        <input
                            type="file"
                            id="productImage"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {productImage && (
                            <img src={productImage} alt="Product thumbnail"
                                 style={{
                                     width: "100px", height: "100px", objectFit: "cover", marginLeft: "10px",
                                     marginBottom: "10px"
                                 }}/>
                        )}

                    </div>
                    <div id="uploadProgress"
                         style={{width: uploadProgress + '%', height: '20px', backgroundColor: '#4CAF50'}}></div>
                    <p>{uploadStatus}</p>
                    <button
                        type='submit'
                        id='login-button'>إضافة
                    </button>
                </form>
            )}


            {/* Edit Product Form */}
            {isEditFormVisible && (
                <section>
                    <div className='items-above-products'>
                        <div className='search-container'>
                            <input
                                className='search-bar'
                                type="text"
                                placeholder="بحث عن منتج (بالاسم، بالباركود)"
                                value={searchTerm}
                                onChange={handleSearch} // Pass the function reference directly without invoking it
                            />
                            <label htmlFor='search-bar'>البحث</label>
                        </div>
                        <div className='search-container'>
                            <select name="itemsPerPage"
                                    id="itemsPerPage"
                                    onChange={handleSelectChange}
                                    value={itemsPerPage} // Bind the select's value to the state
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </select>
                            <label htmlFor='itemsPerPage'>عدد المنتجات</label>
                        </div>
                    </div>

                        <table className='products-table'>
                            <thead>
                            <tr>
                                <th>ازالة</th>
                                <th>تعديل</th>
                                <th>سعر المفرق</th>
                                <th>سعر الجملة</th>
                                <th>رمز المنتج</th>
                                <th>اسم المنتج</th>
                                <th></th>
                                {/* Add more headers as needed */}
                            </tr>
                            </thead>
                            {isLoading ?
                                <tbody>
                                <tr>
                                    <td colSpan='7'>
                                        <spiral-loader
                                            size="50"
                                            speed="0.5"
                                            color="#bb1212"
                                        ></spiral-loader>
                                    </td>

                                </tr>
                                </tbody>

                                :
                                <tbody>

                                {currentProducts
                                    .filter(product =>
                                        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        product.productBarcode.toString().includes(searchTerm.toString())
                                )
                                .map((product, index) => (

                                    <tr key={`${product.id}-${index}`}>

                                        <td>
                                            <button className='order-button'
                                                    onClick={() => {setVisible(true)
                                                    setProduct(product)}}>ازالة
                                            </button>

                                        </td>
                                        <td>
                                            <button className='order-button'
                                                    onClick={() => handleEditClick(product)}>تعديل
                                            </button>
                                        </td>
                                        <td>{product.retailPrice}</td>
                                        <td>{product.wholesalePrice}</td>
                                        <td>{product.productBarcode}</td>
                                        <td>{product.productName}</td>
                                        <td><img src={product.productImage} alt={product.productName}
                                                 className='card-image'/>
                                        </td>
                                        {/* Include the Edit button */}

                                    </tr>
                                ))}
                            </tbody>
                            }
                        </table>

                    <div className='navigation-pane'>
                        {!isLoading && canLoadMore && (
                            <button className="loadMore-button" onClick={() => loadMoreProducts()}
                                    disabled={isLoading}>تحميل المزيد</button>
                        )}
                        {!canLoadMore && (
                            <div>تم عرض جميع المنتجات</div>
                        )}
                    </div>
                </section>
            )}
            {
                isEditFormVisible && editingProduct && (
                    <form id='signInForm' onSubmit={handleProductUpdate}>
                        <label htmlFor="input-field">اسم المنتج</label>
                        <input
                            className='input-field'
                            id="input-field"
                            type="text"
                            value={editingProduct.productName}
                            onChange={(e) => setEditingProduct({...editingProduct, productName: e.target.value})}
                        />
                        <label htmlFor="input-field">الباركود</label>
                        <input
                            className='input-field'
                            id="input-field"
                            type="number"
                            value={removeLeadingZero(editingProduct.productBarcode)}
                            onChange={(e) => setEditingProduct({...editingProduct, productBarcode: e.target.value})}
                            onKeyDown={(e) => handleEnterKey(e, editingProductQuantityRef)} // Use the handler here
                            ref={editingProductBarcodeRef}
                        />
                        <label htmlFor="input-field">الكمية المتوفرة</label>
                        <input
                            className='input-field'
                            id="input-field"
                            type="number"
                            value={removeLeadingZero(editingProduct.quantity)}
                            onChange={(e) =>
                                setEditingProduct({...editingProduct, quantity: e.target.value})}
                            ref={editingProductQuantityRef}
                        />
                        <label htmlFor="input-field">سعر التكلفة (شيكل)</label>
                        <input
                            className='input-field'
                            id="input-field"
                            type="number"
                            value={editingProduct.costPrice}
                            onChange={(e) =>
                                setEditingProduct({...editingProduct, costPrice: e.target.value})}
                        />
                        <label htmlFor="input-field">سعر الجملة (شيكل)</label>
                        <input
                            className='input-field'
                            id="input-field"
                            type="number"
                            value={removeLeadingZero(editingProduct.wholesalePrice)}
                            onChange={(e) =>
                                setEditingProduct({...editingProduct, wholesalePrice: e.target.value})}
                        />
                        <label htmlFor="input-field">سعر المفرق (شيكل)</label>
                        <input
                            className='input-field'
                            id="input-field"
                            type="number"
                            value={removeLeadingZero(editingProduct.retailPrice)}
                            onChange={(e) =>
                                setEditingProduct({...editingProduct, retailPrice: e.target.value})}
                        />
                        <label>صورة المنتج</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpdate}
                        />
                        {editingProduct.productImage && (
                            <img src={editedProductImage ? editedProductImage : editingProduct.productImage} alt="Product"
                                 style={{
                                     width: "100px", height: "100px", objectFit: "cover", marginLeft: "10px",
                                     marginBottom: "10px"
                                 }}/>
                        )}
                        <div id="uploadProgress"
                             style={{width: uploadProgress + '%', height: '20px', backgroundColor: '#4CAF50'}}></div>
                        <p>{uploadStatus}</p>
                        <div className='edit-product-buttons'>
                            <button id='login-button'
                                    onClick={() => setIsEditFormVisible(false)}>الغاء
                            </button>
                            <button id='login-button'
                                    type="submit">تحديث بيانات المنتج
                            </button>

                        </div>

                    </form>

                )}

        </div>
    )
}

export default AddProduct
