import {Navigate} from "react-router-dom";

const Error = () => {
    return (
        <div>
            <Navigate to='/Home'>Home</Navigate>
        </div>
    )
}

export default Error;