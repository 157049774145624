import React from "react";
import { StyleSheet, View, Text, Font } from '@react-pdf/renderer';

Font.register({
    family: 'BalooBhaijaan',
    src: '/fonts/BalooBhaijaan.ttf', // Make sure this path is correct
});

const styles = StyleSheet.create({
    productsContainer: {
        width: 545,
        // height: 480,
        marginTop: 10,
        marginBottom: 10,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    row: {
        flexDirection: 'row',
        marginBottom: 0,
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: '#000',
    },
    text: {
        flex: 1,
        textAlign: 'center',
        fontFamily: 'BalooBhaijaan',
        fontSize: 12,
        borderLeftWidth: 1,
        borderColor: '#000',
        padding: 8, // Adjusted for better alignment
    },
    headerText: {
        fontWeight: 'bold',
        backgroundColor: '#bb1212',
        color: 'white',
        padding: 8, // Consistent padding with other cells
    },
});

// Helper to chunk the products array
const chunkProducts = (products, maxItems) => {
    const chunks = [];
    for (let i = 0; i < products.length; i += maxItems) {
        chunks.push(products.slice(i, i + maxItems));
    }
    return chunks;
};

const ProductItems = ({ products }) => {
    const maxItemsPerPage = 9;
    const productChunks = chunkProducts(products, maxItemsPerPage);

    return productChunks.map((chunk, pageIndex) => (
        <View key={pageIndex} style={styles.productsContainer} wrap={false}>
            {/* Header Row, repeated for each new chunk/page */}
            <View style={styles.row}>
                <Text style={[styles.text, styles.headerText]}>السعر الكلي</Text>
                <Text style={[styles.text, styles.headerText]}>سعر الحبة</Text>
                <Text style={[styles.text, styles.headerText]}>مجموع الكميات</Text>
                <Text style={[styles.text, styles.headerText]}>البونص</Text>
                <Text style={[styles.text, styles.headerText]}>الكمية</Text>
                <Text style={[styles.text, styles.headerText]}>اسم المنتج</Text>
            </View>
            {chunk.map((product, index) => (
                <View key={index} style={styles.row}>
                    <Text style={styles.text}>{product.effectivePrice.toFixed(2)}</Text>
                    <Text style={styles.text}>{(product.effectivePrice / product.quantity).toFixed(2)}</Text>
                    <Text style={styles.text}>{product.totalQuantity}</Text>
                    <Text style={styles.text}>{product.bonusQuantity}</Text>
                    <Text style={styles.text}>{product.quantity}</Text>
                    <Text style={styles.text}>{product.productName}</Text>
                </View>
            ))}
        </View>
    ));
};

export default ProductItems;
