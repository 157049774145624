import React, {useState} from "react";
import {auth} from "../firebase/firebase-config";
import {signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {Store} from "react-notifications-component";


const SignIn = () => {
    let [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    // const [nameOfUser, setNameOfUser] = useState('');
    const handleSignIn = (e) => {
        e.preventDefault();
        email=email+"@aotco.com";

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredentials) => {
                navigate('/Home')
                console.log(userCredentials.user.displayName)
                Store.addNotification({
                    title: "تم تسجيل الدخول",
                    message: `أهلاً بك، ${userCredentials.user.displayName}`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }});

                // console.log(email)
                // setUser({email: userCredentials.user.email})
                // console.log(setUser)
            }).catch((error) => {
            Store.addNotification({
                title: "خطأ",
                message: "المعلومات المدخلة غير صحيحة. يرجى المحاولة مرة أخرى.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});
            })
    }
    document.body.style.backgroundColor = '#555'
    return(
        <div className='sign-in-container'>
            <img id="LogoSignIn" src='/Logo3.png' alt='تسجيل الدخول'/>
            <form id='signInForm' onSubmit={handleSignIn}>
                <input
                    className='input-field'
                    type='text'
                    placeholder='ادخل اسم المستخدم'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                ></input>
                <input
                    className='input-field'
                    type='password'
                    placeholder='ادخل كلمة المرور'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                ></input>
                <button
                    type='submit'
                    id='login-button'>تسجيل الدخول
                </button>
            </form>

        </div>
    )
}

export default SignIn