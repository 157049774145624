import React, {useEffect, useState} from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { spiral } from 'ldrs'
import {Store} from "react-notifications-component";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const SignUp = () => {
    spiral.register("spiral-loader")
    const [userz, setUserz] = useState(null);
    const functions = getFunctions();
    const addUser = httpsCallable(functions, 'addUser');
    const addUserRole = httpsCallable(functions, 'addUserRole');
    let [email, setEmail] = useState('');
    const [name, setName] = useState('')
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('1');
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [isAddUserVisible, setIsAddUserVisible] = useState(false);
    const [isEditUserVisible, setIsEditUserVisible] = useState(false);
    const itemsPerPage = 2;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    const [loading, setLoading] = useState(false); // Loading state
    const [searchTerm, setSearchTerm] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [visible, setVisible] = useState(false);
    const isAccept = useState(true);
    const [pVisible, setPVisible] = useState(false);
    const [newUserPassword, setNewUserPassword] = useState('');
    useEffect(() => {
        setLoading(true); // Set loading to true before fetching
        const fetchUsers = httpsCallable(functions, 'listUsers');
        fetchUsers().then(result => {
            setUsers(result.data.users.filter(user => user.uid !== "0000"));
            setLoading(false);
        }).catch(error => {
            console.error("Error fetching users:", error);
            setLoading(false);
        });
    }, [functions]);

    const startEditUser = (user) => {
        setEditingUser(user);
        setIsEditUserVisible(true);
    };

    const currentUsers = users
        .filter((user) =>
            user.uid.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.displayName.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(indexOfFirstUser, indexOfLastUser);

    const promptForPasswordChange = (uid, newUserPassword) => {
        const newPassword = newUserPassword;
        if (newPassword) {
            const updateUserPassword =
                httpsCallable(functions, 'updateUserPassword');
            updateUserPassword({ uid, newPassword }).then(() => {
                // alert("تم تحديث كلمة السر بنجاح.");
                Store.addNotification({
                    title: "تمت العملية",
                    message: `تم تحديث كلمة السر للمستخدم ${userz.displayName} بنجاح`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});
                setNewUserPassword('');
            }).catch(error => {
                console.error("Error updating password:", error);
                // alert(` ${error.message}خطأ في تحديث كلمة السر `);
                Store.addNotification({
                    title: "خطأ في تحديث كلمة السر",
                    message: `${error.message}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});
                setNewUserPassword('');
            });
        }
    };
    const deleteUser = (uid) => {
        if (isAccept) {
            const deleteUserFunction = httpsCallable(functions, 'deleteUser');
            deleteUserFunction({ uid }).then(() => {
                // alert("تم حذف المستخدم بنجاح");
                Store.addNotification({
                    title: "حذف المستخدم",
                    message: ` تم حذف المستخدم ${userz.displayName} بنجاح `,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});

                setUsers(currentUsers => currentUsers.filter(user => user.uid !== uid));
            }).catch(error => {
                console.error("Error deleting user:", error);
                // alert("خطأ في حذف المستخدم");
                Store.addNotification({
                    title: "خطأ",
                    message: "خطأ في حذف المستخدم",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});
            });
        }
    };
    const totalUsers = users.filter((user) =>
        user.uid.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.displayName.toString().toLowerCase().includes(searchTerm.toLowerCase())
    ).length;

    const totalPages = Math.ceil(totalUsers / itemsPerPage);

    function goToNextPage() {
        setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
    }

    function goToPreviousPage() {
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    }

    // const handleEditUserSubmit = async (e) => {
    //     e.preventDefault();
    //     const updateUserDetails = httpsCallable(functions, 'updateUserDetails');
    //     try {
    //         await updateUserDetails({
    //             uid: editingUser.uid,
    //             email: editingUser.email,
    //             displayName: editingUser.displayName,
    //         });
    //         alert('تم تحديث بيانات المستخدم');
    //         setIsEditUserVisible(false);
    //         setEditingUser(null);
    //         // Optionally refresh the list of users here
    //             const fetchUsers = httpsCallable(functions, 'listUsers');
    //             fetchUsers().then(result => {
    //                 setUsers(result.data.users);
    //             }).catch(error => {
    //                 console.error("Error fetching users:", error);
    //             })
    //     } catch (error) {
    //         console.log("The user email submitted: "+email)
    //         console.error("Error updating user:", error);
    //         alert('خطأ في تحديث بيانات المستخدم!');
    //
    //     }
    // };

    const handleEditUserSubmit = async (e) => {
        setIsSending(true);
        e.preventDefault();
        const updateUserDetails = httpsCallable(functions, 'updateUserDetails');

        try {
            await updateUserDetails({
                uid: editingUser.uid,
                email: editingUser.email,
                displayName: editingUser.displayName,
            });

            // alert('تم تحديث بيانات المستخدم');
            Store.addNotification({
                title: "تمت العملية",
                message: "تم تحديث بيانات المستخدم بنجاح",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});

            setIsSending(false);

            // Update the user in the local state without re-fetching all users
            const updatedUsers = users.map(user => {
                if (user.uid === editingUser.uid) {
                    // Update this user's details
                    return { ...user, email: editingUser.email, displayName: editingUser.displayName };
                }
                return user; // Leave other users unchanged
            });

            setUsers(updatedUsers); // Set the updated users array back to state

            // Reset editing state and hide the edit user form
            setIsEditUserVisible(false);
            setEditingUser(null);
        } catch (error) {
            setIsSending(false);
            console.error("Error updating user:", error);
            // alert('خطأ في تحديث بيانات المستخدم!');
            Store.addNotification({
                title: "خطأ",
                message: "خطأ في تحديث بيانات المستخدم",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});
        }
    };


    // const role = "3"
    const handleSignUp = (e) => {
        setIsSending(true);
        e.preventDefault();
        let email2=email+"@aotco.com";

        addUser({
            uid: email,
            email: email2,
            password: password,
            displayName: name,
        }).then(() => {
                // console.log(result)
            addUserRole({
                uid: email,
                role: role
            }).then(() => {
            }).catch((error) => {
                console.log(error);
            })
            // alert("تمت اضافة المستخدم بنجاح")
            Store.addNotification({
                title: "تمت العملية",
                message: `تمت اضافة المستخدم ${name} بنجاح`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});

            setEmail("");
            setPassword("");
            setRole("1");
            setName("");
            setIsAddUserVisible(false);
            setIsSending(false);

            const newUser = {
                uid: email,  // This assumes your UID is the email
                email: email2,
                displayName: name,
                // Include any other properties you are managing
            };

            setUsers(prevUsers => [...prevUsers, newUser]);

        }).catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.message;
            setIsSending(false);
            // alert(errorMessage);
            Store.addNotification({
                title: "خطأ",
                message: `${errorMessage}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }});
        })
    }

    document.body.style.backgroundColor = 'white'
    return(
        <div className='signup-container'>
            <ConfirmDialog style={{width: "auto"}}
                           visible={visible} onHide={() => setVisible(false)}
                           message='هل أنت متأكد من حذف المستخدم؟'
                           header="تأكيد الإزالة"
                           acceptLabel="نعم"
                           rejectLabel="لا"
                           accept={() => deleteUser(userz.uid)}
                           reject={() => console.log("yes")}
            />
            <Dialog header="تغيير كلمة السر"
                    visible={pVisible} onHide={() => setPVisible(false)}>
                <form id='signInForm'
                      // onSubmit={() => {
                      //     promptForPasswordChange(userz.uid, newUserPassword)
                      //     setPVisible(false)
                      // }}>
                    >
                    <InputText
                        className='input-field-password'
                        placeholder="كلمة السر الجديدة"
                        value={newUserPassword}
                        onChange={(e) => setNewUserPassword(e.target.value)}/>

                    <Button label="تغيير كلمة السر"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default form submission
                                promptForPasswordChange(userz.uid, newUserPassword);
                                setPVisible(false);
                            }} />
                </form>
            </Dialog>
            {/* Button to toggle form visibility */}
            <div className='edit-product-buttons'>
                <button id='login-button' onClick={() => {
                    setIsEditUserVisible(!isEditUserVisible);
                    if (isAddUserVisible) setIsAddUserVisible(false);
                }}>
                    {isEditUserVisible ? 'اخفاء قائمة تعديل الموظفين' : 'تعديل/حذف الموظفين'}
                </button>
                <button id='login-button' onClick={() => {
                    setIsAddUserVisible(!isAddUserVisible);
                    if (isEditUserVisible) setIsEditUserVisible(false);
                }}>
                    {isAddUserVisible ? 'اخفاء قائمة اضافة الموظف' : 'إضافة موظف'}
                </button>

            </div>
            {isAddUserVisible && (
        <div className='signup-container'>
            <form id='signInForm' onSubmit={handleSignUp}>
                <h2>يرجى إضافة معلومات الموظف المطلوبة</h2>
                <label htmlFor="input-field">الإسم</label>
                <input
                    className='input-field'
                    id="input-field"
                    type='text'
                    placeholder='يرجى ادخال الإسم'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                ></input>
                <label htmlFor="input-field">إسم المستخدم</label>
                <input
                    className='input-field'
                    type='text'
                    placeholder='ادخل اسم المستخدم'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                ></input>
                <label htmlFor="input-field">كلمة المرور</label>
                <input
                    className='input-field'
                    type='text'
                    placeholder='ادخل كلمة المرور'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                ></input>
                <label htmlFor="input-field">يرجى اختيار دور الموظف</label>
                <select name="role" id="role"
                        className='input-field'
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        defaultChecked="1">
                    <option value="3">موظف اداري</option>
                    <option value="2">موظف محل</option>
                    <option value="1">مندوب مبيعات</option>
                </select>
                {isSending && (
                    <div>
                        <div>
                            <spiral-loader
                                size="40"
                                speed="0.5"
                                color="#bb1212"
                            ></spiral-loader>
                        </div>
                        <div>جاري الإضافة، يرجى الانتظار</div>
                    </div>
                )}
                <button
                    type='submit'
                    id='login-button'>إضافة
                </button>
            </form>
            <div className="user-message">This is a message</div>
        </div>
            )}
            {isEditUserVisible && (
                <div>
                    {loading && <div>
                        <div>
                            <spiral-loader
                                size="40"
                                speed="0.5"
                                color="#bb1212"
                            ></spiral-loader>
                        </div>
                        <div>جاري التحميل</div>
                    </div>}
                    <table className='products-table'>
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>اسم المستخدم</th>
                            <th>الاسم</th>

                        </tr>
                        </thead>
                        <tbody>
                        {currentUsers.map(user => (

                            <tr key={user.uid}>
                                <td>
                                    <button className='order-button'
                                            onClick={() => {setVisible(true)
                                            setUserz(user)}}>
                                        ازالة
                                    </button>

                                </td>
                                <td>
                                        <button className='order-button'
                                            onClick={() => {setPVisible(true)
                                            setUserz(user)}}>
                                        تغيير كلمة السر
                                    </button>
                                </td>
                                <td>
                                    <button className='order-button'
                                            onClick={() => startEditUser(user)}>
                                        تحديث البيانات
                                    </button>
                                </td>
                                <td>{user.email.split('@')[0]}</td>
                                <td>{user.displayName}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className='navigation-pane'>
                        <button onClick={goToNextPage}
                                disabled={currentPage === totalPages || totalPages === 0}>التالي
                        </button>
                        <div id='navigation-text'>{totalPages > 0 ? `Page ${currentPage} of ${totalPages}` : 'No items'}</div>
                        <button onClick={goToPreviousPage} disabled={currentPage === 1}>السابق</button>

                    </div>
                </div>
            )}
            {isEditUserVisible && editingUser && (
                <form id='signInForm' onSubmit={handleEditUserSubmit}>
                    <h2>التعديل على بيانات: {editingUser.displayName}</h2>
                    <label htmlFor="editName">الاسم</label>
                    <input
                        className='input-field'
                        id="input-field"
                        type="text"
                        value={editingUser.displayName}
                        onChange={(e) =>
                            setEditingUser({...editingUser, displayName: e.target.value})}
                    />
                    <label htmlFor="editEmail">اسم المستخدم</label>
                    <input
                        className='input-field'
                        id="input-field"
                        type="text"
                        value={editingUser.email.split("@")[0]}
                        onChange={(e) =>
                        {
                            const newEmailPrefix = e.target.value;
                            const fullEmail = newEmailPrefix ? `${newEmailPrefix}@aotco.com` : editingUser.email;
                            setEditingUser({...editingUser, email: fullEmail});
                        }}
                    />
                    {isSending && (
                        <div>
                            <div>
                                <spiral-loader
                                    size="40"
                                    speed="0.5"
                                    color="#bb1212"
                                ></spiral-loader>
                            </div>
                            <div>جاري التحديث، يرجى الانتظار</div>
                        </div>
                    )}
                    <div className='edit-product-buttons'>
                        <button id='login-button'
                                onClick={() => setIsEditUserVisible(false)}>الغاء
                        </button>
                        <button id='login-button'
                                type="submit">تحديث بيانات الموظف
                        </button>

                    </div>
                </form>
            )}
        </div>
    )
}

export default SignUp
