import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from "react";

// Register an Arabic font that supports Arabic characters
Font.register({
    family: 'BalooBhaijaan',
    src: '/fonts/BalooBhaijaan.ttf', // Make sure this path is correct
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
    },
    section: {
        // padding: 10,
        marginRight: 25,
        // flexGrow: 1,
        textAlign: 'right',
        fontSize: 12,
        fontFamily: 'BalooBhaijaan', // Make sure this is the name of the font you registered
    },
    text: {
        direction: 'rtl', // Ensure text direction is right-to-left
    },
});

const Header = ({ order }) => (
    <View style={styles.section}>

        {/* Assuming orderDate is a Firestore Timestamp */}
        <Text style={styles.text}>بتاريخ: {order.orderDate.toDate().toLocaleDateString('en-GB')}</Text>
        <Text style={styles.text}>اسم الزبون: {order.customerName}</Text>
        <Text style={styles.text}>عنوان الزبون: {order.customerAddress}</Text>
        <Text style={styles.text}>هاتف الزبون: {order.customerPhone}</Text>
        <Text style={styles.text}>اسم المندوب: {order.employeeName}</Text>
    </View>
);

export default Header;
