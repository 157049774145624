import { useState, useEffect } from 'react';
import { db } from "../firebase/firebase-config";
import {collection, query, orderBy, startAfter, limit, getDocs, where} from 'firebase/firestore';
import algoliasearch from 'algoliasearch/lite';

const useProducts = (itemsPerPage, searchTerm) => {

    const [isFetching, setIsFetching] = useState(false); // Add a state to track fetch status
    const [products, setProducts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);

    const client = algoliasearch('THF4U85QG9', '051ed3cc110e37b5d97170f72e97e936');
    const index = client.initIndex('products');

//     const fetchProducts = async (reset = false) => {
//         // if (isFetching || (!canLoadMore && !reset)) return;
//         //
//         // setIsFetching(true);
//         // setIsLoading(true);
//         //
//         // let q;
//         // if (reset || !lastVisible) {
//         //     setCanLoadMore(true);
//         //     if (searchTerm) {
//         //         // When there's a searchTerm, start filtering from the beginning
//         //         q = query(collection(db, "products"), orderBy("productName"),
//         //             where("productName", ">=", searchTerm),
//         //             where("productName", "<=", searchTerm + '\uf8ff'),
//         //             limit(itemsPerPage));
//         //     } else {
//         //         q = query(collection(db, "products"), orderBy("productName"), limit(itemsPerPage));
//         //     }
//         //     if (reset) {
//         //         setProducts([]);
//         //         const { nbHits } = await index.search(searchTerm || '', { hitsPerPage: 0 });
//         //         setTotalRecords(nbHits);
//         //     }
//         // } else {
//         //     q = query(collection(db, "products"), orderBy("productName"), startAfter(lastVisible), limit(itemsPerPage));
//         // }
//         if (isFetching || (!canLoadMore && !reset)) return;
//
//         setIsFetching(true);
//         setIsLoading(true);
//
//         let q;
//         if (reset || !lastVisible) {
//             setCanLoadMore(true);
//             setProducts([]);  // Clear products on reset or first load
//             if (searchTerm) {
//                 q = query(collection(db, "products"), orderBy("productName"),
//                     where("productName", ">=", searchTerm),
//                     where("productName", "<=", searchTerm + '\uf8ff'),
//                     limit(itemsPerPage));
//             } else {
//                 q = query(collection(db, "products"), orderBy("productName"), limit(itemsPerPage));
//             }
//         } else {
//             q = query(collection(db, "products"), orderBy("productName"), startAfter(lastVisible), limit(itemsPerPage));
//         }
//
//
//         try {
// //             const documentSnapshots = await getDocs(q);
// //             const lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
// //             setLastVisible(lastVisibleDoc);
// //
// //             // const newProducts = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
// //             // setProducts(prevProducts => [...prevProducts, ...newProducts]);
// //
// //             const newProducts = documentSnapshots.docs.map(doc => {
// //                 const data = doc.data();
// //                 return { id: doc.id, ...data };
// //             });
// //
// // // This ensures no undefined entries are added
// //             const filteredProducts = newProducts.filter(product => product !== undefined);
// //             setProducts(prevProducts => [...prevProducts, ...filteredProducts]);
// //
// //             setIsLoading(false);
// //             setCanLoadMore(documentSnapshots.docs.length === itemsPerPage);
//             const documentSnapshots = await getDocs(q);
//             const lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
//             setLastVisible(lastVisibleDoc);
//
//             const newProducts = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             setProducts(prevProducts => {
//                 const existingIds = new Set(prevProducts.map(p => p.id));
//                 const filteredNewProducts = newProducts.filter(p => !existingIds.has(p.id));
//                 return [...prevProducts, ...filteredNewProducts];
//             });
//
//             setIsLoading(false);
//             setCanLoadMore(documentSnapshots.docs.length === itemsPerPage);
//
//         } catch (error) {
//             console.error("Error fetching products:", error);
//             setIsLoading(false);
//         } finally {
//             setIsFetching(false);
//         }
//     };

    const fetchProducts = async (reset = false) => {
        if (isFetching || (!canLoadMore && !reset)) return;

        setIsFetching(true);
        setIsLoading(true);

        let q;
        if (reset || !lastVisible) {
            setCanLoadMore(true);
            setProducts([]);  // Clear products on reset or first load

            if (searchTerm) {
                q = query(collection(db, "products"), orderBy("productName"),
                    where("productName", ">=", searchTerm),
                    where("productName", "<=", searchTerm + '\uf8ff'),
                    limit(itemsPerPage));
            } else {
                q = query(collection(db, "products"), orderBy("productName"), limit(itemsPerPage));
            }
        } else {
            q = query(collection(db, "products"), orderBy("productName"), startAfter(lastVisible), limit(itemsPerPage));
        }


        try {
            const { nbHits } = await index.search(searchTerm || '', { hitsPerPage: 0 });
            setTotalRecords(nbHits);  // Update total records in state
        } catch (error) {
            console.error("Error fetching total records from Algolia:", error);
        }

        try {
            const documentSnapshots = await getDocs(q);
            const lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
            setLastVisible(lastVisibleDoc);

            const newProducts = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProducts(prevProducts => {
                const existingIds = new Set(prevProducts.map(p => p.id));
                const filteredNewProducts = newProducts.filter(p => !existingIds.has(p.id));
                return [...prevProducts, ...filteredNewProducts];
            });

            setIsLoading(false);
            setCanLoadMore(documentSnapshots.docs.length === itemsPerPage);
        } catch (error) {
            console.error("Error fetching products:", error);
            setIsLoading(false);
        } finally {
            setIsFetching(false);
        }
    };

    const searchProducts = async (searchTerm, loadMore = false) => {

        setIsLoading(true);
        let page = loadMore ? Math.floor(products.length / itemsPerPage) : 0;

        try {

            const { hits, nbHits } = await index.search(searchTerm, {
                hitsPerPage: itemsPerPage,
                page: page
            });

            setTotalRecords(nbHits);
            const processedHits = hits.map(hit => ({
                id: hit.objectID, // Ensuring the id field is correct for all items
                ...hit
            }));

            setProducts(prevProducts => {
                if (!loadMore) {
                    // If not loading more, replace the current products entirely
                    return processedHits;
                } else {
                    const existingIds = new Set(prevProducts.map(p => p.id));
                    const newHits = processedHits.filter(hit => !existingIds.has(hit.id));
                    return [...prevProducts, ...newHits];
                }
            });

            setIsLoading(false);
            setCanLoadMore(hits.length === itemsPerPage);
        } catch (error) {
            console.error("Error fetching search results:", error);
            setIsLoading(false);
            setCanLoadMore(false);
        } finally {
            setIsLoading(false);
        }
    };



    //
    // const searchProducts = async (searchTerm, loadMore = false) => {
    //     setIsLoading(true);
    //     let page = loadMore ? Math.floor(products.length / itemsPerPage) : 0;
    //
    //     try {
    //         const { hits, nbHits } = await index.search(searchTerm, {
    //             hitsPerPage: itemsPerPage,
    //             page: page
    //         });
    //         setTotalRecords(nbHits);
    //         const processedHits = hits.map(hit => {
    //             return {
    //                 id: hit.objectID, // Make sure the id field matches
    //                 ...hit
    //             };
    //         });
    //         setProducts(prevProducts => loadMore ? [...prevProducts, ...processedHits] : processedHits);
    //         setCanLoadMore(hits.length === itemsPerPage);
    //     } catch (error) {
    //         console.error("Error fetching search results:", error);
    //         setIsLoading(false);
    //         setCanLoadMore(false);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };




    const removeProduct = (productId) => {
        setProducts(currentProducts => currentProducts.filter(product => product.id !== productId));
    };

    const updateProductQuantities = (updatedProducts) => {
        setProducts(currentProducts => {
            return currentProducts.map(product => {
                const update = updatedProducts.find(p => p.id === product.id);
                return update ? { ...product, quantity: update.newQuantity } : product;
            });
        });
    };

    useEffect(() => {
        fetchProducts(true);  // Initial fetch with true to reset pagination
    }, [itemsPerPage]);// Refetch when itemsPerPage changes

    return { products, isLoading, canLoadMore, fetchProducts,
        searchProducts, updateProductQuantities, totalRecords, removeProduct  };
};

export default useProducts;