import React, { useState, useEffect } from "react";
import {collection, getDocs, query, where, doc, deleteDoc, getDoc, updateDoc} from 'firebase/firestore';
import { db } from "../firebase/firebase-config";
import { PDFDownloadLink } from '@react-pdf/renderer';
// import OrderPDF from './OrderPDF';
import Invoice from './InvoiceData/Invoice'
import AuthDetails from "./AuthDetails";
import { spiral } from 'ldrs'
import {Store} from "react-notifications-component";
import {ConfirmDialog} from "primereact/confirmdialog";

const ViewOrders = () => {

    spiral.register("spiral-loader")
    const [orderz, setOrderz] = useState(null);
    const {isLoading, isAuth: authUser, email, displayName, role, roleNum} = AuthDetails();
    const today = new Date().toISOString().slice(0, 10);
    const [orders, setOrders] = useState([]);
    const [viewDetailsOrderId, setViewDetailsOrderId] = useState(null); // Track which order's details to show
    const [selectedDate, setSelectedDate] = useState(today);
    const [isDeleting, setIsDeleting] = useState(null);
    const [visible, setVisible] = useState(false);
    const isAccept = useState(true);

    useEffect(() => {
        if (selectedDate) {
            fetchOrders(selectedDate);
        }
    }, [selectedDate, isLoading]);

    const fetchOrders = async (date) => {
        // Start and end points of the selected day
        const startDate = new Date(date);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(date);
        endDate.setHours(23, 59, 59, 999);

        const q = query(collection(db, "orders"), where("orderDate", ">=", startDate), where("orderDate", "<=", endDate));
        const querySnapshot = await getDocs(q);
        let ordersArray = [];
        querySnapshot.forEach((doc) => {
            ordersArray.push({ id: doc.id, ...doc.data() });
        });
        if(Number(roleNum) === 1) {
            ordersArray = ordersArray.filter(order => order.employeeName === displayName);
        }
        setOrders(ordersArray);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const toggleViewDetails = (orderId) => {
        setViewDetailsOrderId((prevOrderId) => prevOrderId !== orderId ? orderId : null);
    };

    // const deleteOrder = async (orderId) => {
    //     // Confirmation dialog to make sure the user wants to delete the order
    //     if (window.confirm("هل أنت متأكد من رغبتك في الغاء الطلبية؟")) {
    //         await deleteDoc(doc(db, "orders", orderId));
    //         // After deletion, fetch orders again to update the list
    //         if (selectedDate) {
    //             fetchOrders(selectedDate);
    //         }
    //     }
    // };
    const deleteOrder = async (orderId) => {
        // Confirmation dialog to make sure the user wants to delete the order
        if (isAccept) {
            setIsDeleting(orderId);
            const orderRef = doc(db, "orders", orderId);
            const orderSnap = await getDoc(orderRef);

            if (orderSnap.exists()) {
                const orderDetails = orderSnap.data();
                // Assuming orderDetails.products is an array of {id, quantity} for each product
                for (const product of orderDetails.products) {
                    const productRef = doc(db, "products", product.id);
                    const productSnap = await getDoc(productRef);
                    if (productSnap.exists()) {
                        const currentQuantity = productSnap.data().quantity;
                        const newQuantity = currentQuantity + product.totalQuantity; // Adjust based on your product structure
                        await updateDoc(productRef, { quantity: newQuantity });
                        setIsDeleting(null);
                    } else {
                        console.error(`Product with ID ${product.id} not found.`);
                        setIsDeleting(null);
                    }
                }

                // After restocking, delete the order
                await deleteDoc(orderRef);
                // Fetch orders again to update the list, if a specific date is selected
                if (selectedDate) {
                    fetchOrders(selectedDate);
                }
                Store.addNotification({
                    title: "حذف الطلبية",
                    message: "تم حذف الطلبية بنجاح",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});

                setIsDeleting(null);
            } else {
                console.error("Order not found.");
            }
        }
    };

    const markOrderAsDone = async (orderId) => {
        const orderRef = doc(db, "orders", orderId);

        try {
            await updateDoc(orderRef, {
                isDoneNow: true
            });
            console.log("Order marked as done successfully.");

            // Optionally, refresh the list of orders to reflect the change in the UI
            if (selectedDate) {
                fetchOrders(selectedDate);
            } else {
                // If no specific date is selected, you might want to fetch all orders again
                // Or implement another way to refresh the orders list
            }
        } catch (error) {
            console.error("Error marking order as done:", error);
        }
    };

    return (
        <div>
            <div className='edit-product-buttons'>
                <input
                    type="date"
                    id="datePicker"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
                <label htmlFor="datePicker">اختيار تاريخ الطلبية</label>
            </div>
            <ConfirmDialog  style={{width: "auto"}}

                            visible={visible} onHide={() => setVisible(false)}
                            message="هل أنت متأكد من إلغاء الطلبية؟"
                            header="تأكيد الإلغاء"
                            acceptLabel="نعم"
                            rejectLabel="لا"
                            accept={() => deleteOrder(orderz.id)}
                            reject={() => console.log("yes")}
            />
            {isLoading ? (
                <p>Loading...</p> // Display a loading message or a spinner
            ) : (
            <table className='products-table'>
            <thead>
            <tr>
                <th></th>
                <th>الغاء</th>
                <th>تفاصيل الطلبية</th>
                <th>الحالة</th>
                <th> (شيكل) مجموع السعر</th>
                <th>اسم المندوب</th>
                <th>العنوان</th>
                <th>رقم الهاتف</th>
                <th> اسم الزبون</th>

            </tr>
            </thead>
                {orders.length > 0 ? orders.map((order) => (
                <tbody>
                    <React.Fragment key={order.id}>
                        {/*{console.log(order)}*/}
                        <tr>
                            <td>
                                <button className='order-button'>
                                    <PDFDownloadLink document={<Invoice order={order} />} fileName={`order_${order.id}.pdf`}
                                        style={{ textDecoration: 'none'
                                        , color: 'white'
                                        }}
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? '...' : 'PDF'
                                        }
                                    </PDFDownloadLink>
                                </button>
                            </td>
                            <td>{order.isDoneNow ? 'تم تجهيز الطلبية' : (
                                <button className='order-button' onClick={() => {setVisible(true)
                                setOrderz(order)}}>
                                    الغاء الطلبية
                                </button>
                            )}

                           </td>
                            <td>
                                <button className='order-button' onClick={() => toggleViewDetails(order.id)}>
                                    {viewDetailsOrderId === order.id ? 'اخفاء تفاصيل' : 'عرض تفاصيل'}
                                </button>
                            </td>

                            <td>{order.isDoneNow ? "جاهزة" : (
                                <>
                                    غير جاهزة <br/>
                                    {parseInt(roleNum) > 1 && (
                                    <button onClick={() => markOrderAsDone(order.id)}>
                                        تحديث الى جاهزة
                                    </button>
                                        )}
                                </>

                            )}</td>
                            <td>{order.totalPrice}</td>
                            <td>{order.employeeName}</td>
                            <td>{order.customerAddress}</td>
                            <td>{order.customerPhone}</td>
                            <td>{order.customerName}</td>
                        </tr>
                        {viewDetailsOrderId === order.id && (
                            <tr>
                                <td colSpan="9">
                                    <table className='products-table'>
                                        <thead>
                                        <tr>
                                            <th>السعر</th>
                                            <th>مجموع الكميات</th>
                                            <th>الكمية الاضافية</th>
                                            <th>الكمية</th>
                                            <th>اسم المنتج</th>
                                            {/* Add more product details columns as needed */}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {order.products.map((product, index) => (
                                            <tr key={index}>
                                                <td>{product.effectivePrice}</td>
                                                <td>{product.totalQuantity}</td>
                                                <td>{product.bonusQuantity}</td>
                                                <td>{product.quantity}</td>
                                                <td>{product.productName}</td>
                                                {/* Render more product details as needed */}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                        )}
                        {isDeleting === order.id && (
                            <tr>
                                <td colSpan="9">
                                    <table className='products-table'>
                                        <tbody>
                                        <tr>
                                            <td colSpan="9">
                                                <spiral-loader
                                                    size="40"
                                                    speed="0.5"
                                                    color="#bb1212"
                                                ></spiral-loader>
                                                <p>جاري حذف الطلبية</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>


                </tbody>
                )) : (<tbody>
                <tr>
                    <td colSpan='9'>
                        لا يوجد طلبيات لعرضها
                    </td>
                </tr>
                </tbody>)}
            </table>
            )}
        </div>
    );
};

export default ViewOrders;
