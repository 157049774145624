import {StyleSheet, Document, Page } from '@react-pdf/renderer';
import {  Text } from '@react-pdf/renderer';
import Logo from './Logo';
import Header from './Header';
import ProductItems from './ProductItems';
import Totals from "./Totals";
import Footer from './Footer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        size: 'A4',
    },
    productContainer: {
        flexGrow: 1,
        marginBottom: 30, // This ensures there is a buffer before the fixed Text elements
    },
    content: {
        flex: 1,
        marginBottom: 20, // This ensures there is space before the footer
    },
    footer: {
        marginTop: 20, // Space above footer
    },
    pageNumber: {
        position: 'absolute',
        bottom: 30,
        right: 25,
        fontSize: 9,

    },
    credits: {
        position: 'absolute',
        bottom: 30,
        left: 25,
        fontSize: 9,

    },
    // ... other styles if needed
});

const Invoice = ({ order }) => (

    <Document>
        <Page size="A4" style={styles.page}>
            <Logo />
            <Header order={order} />
            <ProductItems products={order.products} />
            <Totals order={order} />
            <Footer />
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                fixed
            />
            <Text
                style={styles.credits}
                render={() => `Abu Obeid CRM: by Eng. Anas Assi - 0595959159`}
                fixed
            />
                {/* You can add additional components or sections as needed */}
        </Page>
    </Document>
);

export default Invoice;
