import React, {useCallback, useEffect, useRef, useState} from "react";
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from "../firebase/firebase-config";
import AuthDetails from "./AuthDetails";
import { RiDeleteBin6Fill } from "react-icons/ri";
import useProducts from './useProducts';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { spiral } from 'ldrs'
import {SiAddthis} from "react-icons/si";
import {Dialog} from "primereact/dialog";

const Home = () => {
    spiral.register("spiral-loader")
    // Hook for navigation
    // const navigate = useNavigate();
    // const {isLoading, isAuth: authUser, email} = AuthDetails();
    const [imgVisible, setImgVisible] = useState(false);
    const [imgToView, setImgToView] = useState('');
    const {isAuth: authUser, displayName, roleNum} = AuthDetails();
    // const [products, setProducts] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [order, setOrder] = useState([]);
    const [customerName, setCustomerName] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [orderType, setOrderType] = useState(''); // 'retail' or 'wholesale'
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [showWholesaleChoice, setShowWholesaleChoice] = useState(false);
    const [tempProductId, setTempProductId] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const { products, isLoading, canLoadMore,
        fetchProducts, searchProducts,
        updateProductQuantities, totalRecords } = useProducts(itemsPerPage);
    const orderDetailsRef = useRef(null);
    const wholesaleChoiceRef = useRef(null);
    const productDetailsRef = useRef(null);

    const [orderSent, setOrderSent] = useState(false);

    useEffect(() => {
        if (showWholesaleChoice && wholesaleChoiceRef.current) {
            wholesaleChoiceRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [showWholesaleChoice]);


    useEffect(() => {
        if (showOrderDetails && orderDetailsRef.current) {
            orderDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [showOrderDetails]);

    useEffect(() => {
        if (orderSent && productDetailsRef.current) {
            productDetailsRef.current.scrollIntoView({ behavior: "smooth" });
            setOrderSent(false); // Reset the state if needed
        }
    }, [orderSent]);

    useEffect(() => {
        fetchProducts(true); // Initial fetch, 'true' to reset pagination
    }, []);


    function debounce(func, wait) {
        let timeout;
        return {
            call: function(...args) {
                clearTimeout(timeout);
                timeout = setTimeout(() => func(...args), wait);
            },
            cancel: function() {
                clearTimeout(timeout);
            }
        };
    }

    const debouncedSearch = useCallback(debounce((nextSearchTerm) => {
        if (nextSearchTerm.trim() !== '') {
            searchProducts(nextSearchTerm);
        } else {
            fetchProducts(true);
        }
    }, 1000), []); // Dependency array left empty to ensure debounce function does not reset

    useEffect(() => {
        debouncedSearch.call(searchTerm);

        // Cleanup function to cancel the debounce if the component unmounts or if the effect re-runs
        return () => debouncedSearch.cancel();
    }, [searchTerm, debouncedSearch]);

    // Handler for search input change
    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm('');
        setSearchTerm(e.target.value);
    };

    // Handler for search input change
    // useEffect(() => {
    //     const fetchProducts = async () => {
    //         const querySnapshot = await getDocs(collection(db, "products"));
    //         const productsArray = [];
    //         querySnapshot.forEach((doc) => {
    //             // Assuming your documents contain fields 'name' and 'price'
    //             productsArray.push({ id: doc.id, ...doc.data() });
    //         });
    //         setProducts(productsArray);
    //     };
    //
    //     fetchProducts().then(() => {
    //     }).catch((error) => {
    //         alert(error)
    //     });
    // }, []);

    const currentProducts = products.filter(product =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.productBarcode.toString().toLowerCase().includes(searchTerm.toLowerCase())
    ).filter(product => product.quantity > 0);


    if (authUser) {
        document.body.style.backgroundColor = 'white';
    }


    // const submitOrder = async () => {
    //     // Construct the order object
    //     const orderDetails = {
    //         employeeName: displayName,
    //         customerName,
    //         customerAddress,
    //         customerPhone,
    //         products: order.map(({ id, productName, productBarcode, quantity, bonusQuantity, effectivePrice }) => ({
    //             id,
    //             productName,
    //             productBarcode,
    //             quantity,
    //             bonusQuantity,
    //             totalQuantity: (Number(quantity) + Number(bonusQuantity)),
    //             effectivePrice
    //         })),
    //         totalPrice,
    //         totalQuantity: totalQuantities,
    //         orderDate: new Date()
    //     };
    //
    //     try {
    //         // Add the order to the Firestore database
    //         await addDoc(collection(db, "orders"), orderDetails);
    //         alert('تم ارسال الطلبية بنجاح');
    //
    //         setOrder([]);
    //         setCustomerName('');
    //         setCustomerAddress('');
    //         setCustomerPhone('');
    //         setShowOrderDetails(false); // Hide the order-details table
    //         setOrderType(''); // Reset order type to ensure the user is prompted again
    //         setShowWholesaleChoice(false);
    //
    //     } catch (error) {
    //         console.error("عذراً، لم يتم ارسال الطلبية", error);
    //         alert('عذراً، لم يتم ارسال الطلبية. تأكد من الاتصال بالانترنت أو من معلومات الموظف.');
    //     }
    // };

    const handleSelectChange = (event) => {
        setItemsPerPage(Number(event.target.value));
    };

    const scrollToProductDetails = () => {
        if (productDetailsRef.current) {
            productDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const submitOrder = async () => {
        // Construct the order object
        setIsSending(true);
        let exceedQuantityFlag = false;

        for (const product of order) {

            const productRef = doc(db, "products", product.id);
            const productSnap = await getDoc(productRef);
            if (productSnap.exists())  {
                const availableQuantity = productSnap.data().quantity;
                const requestedQuantity = Number(product.quantity) + Number(product.bonusQuantity);
                if (requestedQuantity > availableQuantity) {
                    console.error(`Error: Requested quantity for ${product.productName} exceeds available quantity.`);
                    Store.addNotification({
                        title: "خطأ",
                        message: `خطأ: الكمية المطلوبة لـ${product.productName} تتجاوز الكمية المتاحة.`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                    // alert(`خطأ: الكمية المطلوبة لـ${product.productName} تتجاوز الكمية المتاحة.`);
                    exceedQuantityFlag = true;
                    break; // Exit loop as we found a product exceeding available quantity
                }
                if (requestedQuantity === 0) {
                    Store.addNotification({
                        title: "خطأ",
                        message: `خطأ: لا يمكن طلب منتج مع كمية 0. مصدر الخطأ: ${product.productName}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                    // alert(`خطأ: لا يمكن طلب منتج مع كمية 0. مصدر الخطأ: ${product.productName}`)
                    exceedQuantityFlag = true;
                    break;
                }
            } else {
                console.log(`No product found with ID: ${product.id}`);
                exceedQuantityFlag = true; // Assume error if product not found
                break; // Exit loop as product not found
            }
        }

        // Proceed only if all quantities are within available limits
        if (!exceedQuantityFlag) {
            const orderDetails = {
                employeeName: displayName,
                customerName,
                customerAddress,
                customerPhone,
                products: order.map(({id, productName, productBarcode, quantity, bonusQuantity, effectivePrice}) => ({
                    id,
                    productName,
                    productBarcode,
                    quantity,
                    bonusQuantity,
                    totalQuantity: (Number(quantity) + Number(bonusQuantity)),
                    effectivePrice
                })),
                totalPrice,
                totalQuantity: totalQuantities,
                isDoneNow: false,
                orderDate: new Date()
            };

            try {

                // Add the order to the Firestore database
                await addDoc(collection(db, "orders"), orderDetails);
                // alert('تم ارسال الطلبية بنجاح');
                Store.addNotification({
                    title: "تمت العملية",
                    message: "تم ارسال الطلبية بنجاح",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                setIsSending(false);
                setOrderSent(true);
                const updatedProducts = []
                // Update product quantities in the Firestore database
                for (const product of order) {
                    const productRef = doc(db, "products", product.id);
                    // Ensure this async operation completes before moving to the next.
                    // This prevents any race conditions or overlapping updates.
                    const productSnap = await getDoc(productRef);
                    if (productSnap.exists() && !exceedQuantityFlag) { // Check flag to avoid updates if an error was found
                        const currentQuantity = productSnap.data().quantity;
                        const newQuantity = currentQuantity - (Number(product.quantity) + Number(product.bonusQuantity));

                        if (newQuantity >= 0) {
                            await updateDoc(productRef, { quantity: newQuantity });
                            updatedProducts.push({ id: product.id, newQuantity });
                        } else {
                            // Handle error state: quantity would become negative.
                            exceedQuantityFlag = true; // Prevent further Firestore updates
                            Store.addNotification({
                                title: "خطأ",
                                message: `عذراً، لم يتم ارسال الطلبية. هناك خطأفي الكميات، اسم المنتج: ${product.productName}`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: true
                                }
                            });
                            // alert(`Quantity error for product ${product.productName}`);
                            break; // Exit loop early
                        }
                    }
                }

// Call updateProductQuantities once, after the loop
                if (!exceedQuantityFlag && updatedProducts.length > 0) {
                    updateProductQuantities(updatedProducts);
                }

                // Update the local state with the refreshed product data
                // setProducts(prevProducts => {
                //     return prevProducts.map(product => {
                //         const refreshedProduct = refreshedProducts.find(p => p.id === product.id);
                //         return refreshedProduct ? refreshedProduct : product;
                //     });
                // });


                setOrder([]);
                setCustomerName('');
                setCustomerAddress('');
                setCustomerPhone('');
                setShowOrderDetails(false); // Hide the order-details table
                setOrderType(''); // Reset order type to ensure the user is prompted again
                setShowWholesaleChoice(false);



            } catch (error) {
                setIsSending(false)
                console.error("عذراً، لم يتم ارسال الطلبية", error);
                Store.addNotification({
                    title: "فشل",
                    message: "عذراً، لم يتم ارسال الطلبية. تأكد من الاتصال بالانترنت أو من معلومات الموظف.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } else {
            setIsSending(false)
            // Handling for case where quantity exceeds availability
            console.log("لم يتم ارسال الطلبية، تحقق من الكميات!!");
        }
    };


    // function addToOrder(productId) {
    //     const productIndex = order.findIndex((item) => item.id === productId);
    //     if (productIndex > -1) {
    //         // Product is already in the order, update quantity and maybe price if needed
    //         const updatedOrder = [...order];
    //         updatedOrder[productIndex].quantity += 1;
    //         // Recalculate the price with the updated quantity
    //         updatedOrder[productIndex].effectivePrice =
    //             (updatedOrder[productIndex].wholesalePrice) * updatedOrder[productIndex].quantity;
    //         setOrder(updatedOrder);
    //     } else {
    //         // Product is not in the order, add it with quantity 1
    //         const productToAdd = products.find((product) => product.id === productId);
    //         if (productToAdd) {
    //             const newProduct = {
    //                 ...productToAdd,
    //                 quantity: 1,
    //                 bonusQuantity: 0,
    //                 effectivePrice: (productToAdd.wholesalePrice) // Assuming discount is a property of your product
    //             };
    //             setOrder((currentOrder) => [...currentOrder, newProduct]);
    //         }
    //     }
    // }
    // function addToOrder(productId) {
    //     // Prompt the user to choose the order type
    //     const chosenOrderType = window.prompt('Is this a retail or wholesale order? (Enter "retail" or "wholesale")');
    //
    //     // Validate the input
    //     if (chosenOrderType !== 'retail' && chosenOrderType !== 'wholesale') {
    //         alert('Please enter "retail" or "wholesale".');
    //         return; // Exit the function if the input is invalid
    //     }
    //
    //     setOrderType(chosenOrderType); // Update the order type state
    //
    //     const productIndex = order.findIndex((item) => item.id === productId);
    //     if (productIndex > -1) {
    //         // Product is already in the order, update quantity
    //         const updatedOrder = [...order];
    //         updatedOrder[productIndex].quantity += 1;
    //         setOrder(updatedOrder);
    //     } else {
    //         // Product is not in the order, add it with quantity 1
    //         const productToAdd = products.find((product) => product.id === productId);
    //         if (productToAdd) {
    //             const priceKey = chosenOrderType === 'retail' ? 'retailPrice' : 'wholesalePrice';
    //             const newProduct = {
    //                 ...productToAdd,
    //                 quantity: 1,
    //                 bonusQuantity: 0,
    //                 effectivePrice: productToAdd[priceKey] // Use the selected price type
    //             };
    //             setOrder((currentOrder) => [...currentOrder, newProduct]);
    //         }
    //     }
    //
    //     setShowOrderDetails(true); // Show the order-details table
    // }
    // function addToOrder(productId) {
    //     let chosenOrderType = orderType;
    //
    //     // Only prompt for order type if the order-details table is not already visible
    //     if (!showOrderDetails) {
    //         chosenOrderType = window.prompt('Is this a retail or wholesale order? (Enter "retail" or "wholesale")');
    //
    //         // Validate the input
    //         if (chosenOrderType !== 'retail' && chosenOrderType !== 'wholesale') {
    //             alert('Please enter "retail" or "wholesale".');
    //             return; // Exit the function if the input is invalid
    //         }
    //
    //         setOrderType(chosenOrderType); // Update the order type state
    //         setShowOrderDetails(true); // Show the order-details table
    //     }
    //
    //     const productIndex = order.findIndex((item) => item.id === productId);
    //     if (productIndex > -1) {
    //         // Product is already in the order, update quantity
    //         const updatedOrder = [...order];
    //         updatedOrder[productIndex].quantity += 1;
    //         setOrder(updatedOrder);
    //     } else {
    //         // Product is not in the order, add it with quantity 1
    //         const productToAdd = products.find((product) => product.id === productId);
    //         if (productToAdd) {
    //             const priceKey = chosenOrderType === 'retail' ? 'retailPrice' : 'wholesalePrice';
    //             const newProduct = {
    //                 ...productToAdd,
    //                 quantity: 1,
    //                 bonusQuantity: 0,
    //                 effectivePrice: productToAdd[priceKey] // Use the selected price type
    //             };
    //             setOrder((currentOrder) => [...currentOrder, newProduct]);
    //         }
    //     }
    // }
    // function addToOrder(productId) {
    //     let chosenOrderType = orderType;
    //
    //     // Only prompt for order type if the order-details table is not already visible
    //     if (!showOrderDetails) {
    //         chosenOrderType = window.prompt('Is this a retail or wholesale order? (Enter "retail" or "wholesale")');
    //
    //         // Validate the input
    //         if (chosenOrderType !== 'retail' && chosenOrderType !== 'wholesale') {
    //             alert('Please enter "retail" or "wholesale".');
    //             return; // Exit the function if the input is invalid
    //         }
    //
    //         setOrderType(chosenOrderType); // Update the order type state
    //         setShowOrderDetails(true); // Show the order-details table
    //     }
    //
    //     const productIndex = order.findIndex((item) => item.id === productId);
    //     const productToAdd = products.find((product) => product.id === productId);
    //
    //     if (productIndex > -1) {
    //         // Product is already in the order, update quantity
    //         const updatedOrder = [...order];
    //         updatedOrder[productIndex].quantity += 1;
    //         // Recalculate the effectivePrice based on the new quantity and order type
    //         const priceKey = chosenOrderType === 'retail' ? 'retailPrice' : 'wholesalePrice';
    //         updatedOrder[productIndex].effectivePrice = updatedOrder[productIndex].quantity * productToAdd[priceKey];
    //         setOrder(updatedOrder);
    //     } else if (productToAdd) {
    //         // Product is not in the order, add it with quantity 1
    //         const priceKey = chosenOrderType === 'retail' ? 'retailPrice' : 'wholesalePrice';
    //         const newProduct = {
    //             ...productToAdd,
    //             quantity: 1,
    //             bonusQuantity: 0,
    //             effectivePrice: productToAdd[priceKey] // Initial price based on the selected order type
    //         };
    //         setOrder((currentOrder) => [...currentOrder, newProduct]);
    //     }
    // }

    function proceedToAddProduct(productId, chosenOrderType) {
        const productIndex = order.findIndex(item => item.id === productId);
        const productToAdd = products.find(product => product.id === productId);

        if (productIndex > -1) {
            // Product is already in the order, update quantity
            const updatedOrder = [...order];
            updatedOrder[productIndex].quantity += 1;
            // Recalculate the effectivePrice based on the new quantity and order type
            const priceKey = chosenOrderType === 'retail' ? 'retailPrice' : 'wholesalePrice';
            updatedOrder[productIndex].effectivePrice = updatedOrder[productIndex].quantity * productToAdd[priceKey];
            setOrder(updatedOrder);
        } else if (productToAdd) {
            // Product is not in the order, add it with quantity 1
            const priceKey = chosenOrderType === 'retail' ? 'retailPrice' : 'wholesalePrice';
            const newProduct = {
                ...productToAdd,
                quantity: 1,
                bonusQuantity: 0,
                effectivePrice: productToAdd[priceKey] // Initial price based on the selected order type
            };
            setOrder(currentOrder => [...currentOrder, newProduct]);
        }
    }


    function addToOrder(productId) {
        if (!showOrderDetails) {
            setTempProductId(productId); // Save the product ID for later
            setShowWholesaleChoice(true); // Show the choice UI to the user
        } else {
            // If the order-details table is already visible, and orderType is known,
            // you can directly add the product as before, using existing logic.
            proceedToAddProduct(productId, orderType);

            if (showOrderDetails && orderDetailsRef.current) {
                orderDetailsRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }

    }
    function handleWholesaleChoice(isWholesale) {
        const chosenOrderType = isWholesale ? 'wholesale' : 'retail';
        setShowWholesaleChoice(false); // Hide the choice UI
        proceedToAddProduct(tempProductId, chosenOrderType); // Now proceed to add the product with the chosen order type
        setOrderType(chosenOrderType); // Update the order type for the current order
        setShowOrderDetails(true); // Ensure order details are visible
    }

    // function increaseBonusQuantity(productId) {
    //     const productIndex = order.findIndex((item) => item.id === productId);
    //     if (productIndex > -1) {
    //         const updatedOrder = [...order];
    //         updatedOrder[productIndex].bonusQuantity += 1;
    //         setOrder(updatedOrder);
    //     }
    // }
    function setBonusQuantity(productId, newBonusQuantity) {
        const productIndex = order.findIndex((item) => item.id === productId);
        if (productIndex > -1) {
            const updatedOrder = [...order];
            // Ensure the bonus quantity is non-negative
            updatedOrder[productIndex].bonusQuantity = Math.max(0, newBonusQuantity);
            setOrder(updatedOrder);
        }
    }
    // function setQuantity(productId, newQuantity) {
    //     const productIndex = order.findIndex((item) => item.id === productId);
    //     if (productIndex > -1 && newQuantity >= 0) { // Ensure the quantity is non-negative
    //         const updatedOrder = [...order];
    //         updatedOrder[productIndex].quantity = newQuantity;
    //         // Recalculate the effective price based on the new quantity
    //         updatedOrder[productIndex].effectivePrice = (updatedOrder[productIndex].wholesalePrice - (updatedOrder[productIndex].discount || 0)) * newQuantity;
    //         setOrder(updatedOrder);
    //     }
    // }
    function setQuantity(productId, newQuantity) {
        const productIndex = order.findIndex((item) => item.id === productId);
        if (productIndex > -1 && newQuantity >= 0) { // Ensure the quantity is non-negative
            const updatedOrder = [...order];
            updatedOrder[productIndex].quantity = newQuantity;

            // Find the product to get its prices
            const product = products.find((p) => p.id === productId);
            if (!product) return; // Early return if product is not found, though this should not happen

            // Recalculate the effective price based on the new quantity and order type
            const priceKey = orderType === 'retail' ? 'retailPrice' : 'wholesalePrice';
            updatedOrder[productIndex].effectivePrice = newQuantity * product[priceKey];
            setOrder(updatedOrder);
        }
    }

    function removeFromOrder(productId) {
        const updatedOrder = order.filter(product => product.id !== productId);
        setOrder(updatedOrder);

        // Check if the order is now empty
        if (updatedOrder.length === 0) {
            setShowOrderDetails(false); // Hide the order-details table
            setOrderType(''); // Reset order type
            setShowWholesaleChoice(false); // Ensure wholesale choice is not shown
        }
    }


    function handleEnterPress(e) {
        if (e.key === 'Enter') {
            const filteredProducts = products.filter((product) =>
                product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.productBarcode.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );

            if (filteredProducts.length === 1) {
                addToOrder(filteredProducts[0].id);
            }
        }
    }
    const filterWadfowProducts = () => {
        if (searchTerm === 'وادفو') {

        } else {
            setItemsPerPage(10); // This changes the pagination size
            setSearchTerm("وادفو"); // This changes the search criteria
            // Reset pagination state if necessary
            fetchProducts(true);
        }
        // Initial fetch with a reset to start from the beginning
    }

    const totalPrice = order.reduce((acc, product) => acc + product.effectivePrice, 0);
    const totalQuantities = order.reduce((acc, product) => acc + product.quantity + product.bonusQuantity, 0);
    const removeLeadingZero = (value) => {
        // Handle cases where value is null or undefined
        if (!value) return "";
        return value.toString().replace(/^0+/, "");
    };

    const loadMoreProducts = async () => {
        if (searchTerm) {
            await searchProducts(searchTerm, true);
        } else {
            await fetchProducts(false);
        }
    };

    return (
        <div>
            <h4 className="instr">لانشاء طلبية، اختر منتج من قائمة المنتجات او استعمل خاصية البحث</h4>
            <Dialog header="عرض الصورة"
                    visible={imgVisible} onHide={() => setImgVisible(false)}>
                <img src={imgToView}
                     alt={"فش صورة يا حج :)"}
                     height="260px"
                     onClick={() => setImgVisible(false)}
                />
            </Dialog>
            <section className='homepage-components'>
                <section ref={productDetailsRef} className='product-details'>
                    <div className='items-above-products'>
                        <div className='search-container'>
                            <input
                                className='search-bar'
                                type="text"
                                placeholder="بحث عن منتج (بالاسم، بالباركود)"
                                value={searchTerm}
                                onChange={handleSearch} // Pass the function reference directly without invoking it
                                onKeyDown={(e) => handleEnterPress(e)}
                            />
                            <label htmlFor='search-bar'>البحث</label>
                        </div>
                        <div className='filters-container'>
                            <a onClick={filterWadfowProducts}><img id='brand-logo' src='/logo-wadfow.png' alt='noimg'/></a>
                        </div>
                        <div className='search-container'>
                            <select name="itemsPerPage"
                                    id="itemsPerPage"
                                    onChange={handleSelectChange}
                                    value={itemsPerPage}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </select>
                            <label htmlFor='itemsPerPage'> عدد الأصناف في الصفحة</label>
                        </div>
                    </div>
                    {searchTerm === '' ? <div className='search-feedback'>عدد الاصناف المتوفرة: {totalRecords}</div> :
                        <div className='search-feedback'>نتيجة البحث: {totalRecords} صنف </div>}
                        <table className='products-table'>
                            <thead>
                            <tr>
                                <th>اضافة</th>
                                <th>الكمية المتوفرة</th>
                                {parseInt(roleNum) >= 2 &&
                                <th>سعر المفرق</th>
                                }
                                <th>سعر الجملة</th>
                                {/*<th>رمز المنتج</th>*/}
                                <th>اسم المنتج</th>
                                <th></th>
                                {/* Add more headers as needed */}
                            </tr>
                            </thead>
                                <tbody>
                                {currentProducts
                                    .filter(product =>
                                        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        product.productBarcode.toString().includes(searchTerm.toString())
                                )
                                .map((product, index) => (
                                    <tr key={`${product.id}-${index}`}>
                                        <td>
                                            <button className='order-button'
                                                    onClick={() => addToOrder(product.id)}>اضافة
                                                للطلبية
                                            </button>
                                        </td>
                                        <td>{product.quantity}</td>
                                        {parseInt(roleNum) >= 2 &&
                                        <td>{product.retailPrice}</td>
                                        }
                                        <td>{product.wholesalePrice}</td>
                                        {/*<td>{product.productBarcode}</td>*/}
                                        <td>{product.productName}</td>
                                        <td><img src={product.productImage} alt={product.productName}
                                                 onClick={() => {setImgToView(product.productImage)
                                                 setImgVisible(true)}}
                                                 onError={(e) =>
                                                     e.currentTarget.src = "https://firebasestorage.googleapis.com/v0/b/" +
                                                         "abu-obeid.appspot.com/o/NoImage.png?alt=media" +
                                                         "&token=59024f04-3fe4-4d24-8e98-cd2fda7886d1"}
                                                 className='card-image'/>
                                        </td>
                                        {/* Include more fields as needed */}
                                    </tr>
                                ))}
                                {isLoading && (
                                    <tr>
                                        <td colSpan='7'>
                                            <spiral-loader
                                                size="40"
                                                speed="0.5"
                                                color="#bb1212"
                                            ></spiral-loader>
                                        </td>
                                    </tr>
                                    )}
                            </tbody>

                        </table>

                    <div className='navigation-pane'>
                        {!isLoading && canLoadMore && (
                            <button className="loadMore-button" onClick={() => loadMoreProducts()}
                                    disabled={isLoading}>تحميل المزيد</button>
                        )}
                        {!canLoadMore && (
                            <div>تم عرض جميع المنتجات</div>
                        )}
                    </div>
                </section>
                {parseInt(roleNum) >= 2 ?
                showWholesaleChoice && (
                    <div ref={wholesaleChoiceRef}>
                        <p>يرجى اختيار نوع الطلبية</p>
                        <button className='order-button'
                                onClick={() => handleWholesaleChoice(false)}>مفرق</button>
                        <button className='order-button'
                                onClick={() => handleWholesaleChoice(true)}>جملة</button>
                    </div>
                ) : showWholesaleChoice && (
                    <div ref={wholesaleChoiceRef}>
                    <p>يرجى اختيار نوع الطلبية</p>
                    <button className='order-button'
                            onClick={() => handleWholesaleChoice(true)}>جملة
                    </button>
                    </div>
                )}
                <section ref={orderDetailsRef} className='order-details'
                         style={{display: showOrderDetails ? 'block' : 'none'}}>

                    <h4 id='order-type'>{orderType === 'wholesale' ? 'انشاء طلبية جملة' : 'انشاء طلبية مفرق'}</h4>
                    <table className='products-table'>
                        <thead>
                        <tr>
                        <th>السعر</th>
                            <th>الكمية الاجمالية</th>
                            <th>البونص</th>
                            <th>الكمية</th>
                            {/*<th>رمز المنتج</th>*/}
                            <th>اسم المنتج</th>
                            <th>ازالة</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order.map((product, index) => (
                            <React.Fragment key={product.id}>
                            <tr>
                                        <td>{(product.effectivePrice).toFixed(2)}</td>
                                        <td>{product.quantity + product.bonusQuantity}</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={removeLeadingZero(product.bonusQuantity)}
                                                onChange={(e) =>
                                                    setBonusQuantity(product.id,
                                                        parseInt(e.target.value) || 0)}
                                                min="0"
                                                size="4"
                                                tabIndex={2 + index * 3}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={removeLeadingZero(product.quantity)}
                                                onChange={(e) =>
                                                    setQuantity(product.id,
                                                        parseInt(e.target.value) || 0)}
                                                min="0"
                                                size="4"
                                                tabIndex={1 + index * 3}
                                            />
                                        </td>
                                        {/*<td>{product.productBarcode}</td>*/}
                                        <td>{product.productName}</td>
                                        <td>
                                            <button className='remove-button' onClick={() =>
                                                removeFromOrder(product.id)}
                                                tabIndex={3 + index * 3}>
                                                <RiDeleteBin6Fill/>
                                            </button>
                                        </td>
                                    </tr>
                                    { // Display warning if conditions are met
                                        (product.quantity + product.bonusQuantity > products.find(p => p.id === product.id)?.quantity || product.quantity + product.bonusQuantity === 0) && (
                                            <tr>
                                                <td colSpan="6">
                                                    <div style={{
                                                        backgroundColor: 'lightcoral',
                                                        color: 'darkred',
                                                        padding: '10px',
                                                        margin: '5px 0',
                                                        borderRadius: '5px'}}>
                                                        تحذير: الكمية المطلوبة تتجاوز الكمية المتاحة أو هي 0
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </table>
                    <div>
                        <button className='order-button'
                        onClick={scrollToProductDetails}> إضافة منتج<i>  <SiAddthis /></i></button>
                    </div>
                        <div className="order-summary">
                            <div className='totals'>مجموع السعر: {totalPrice.toFixed(2)} شيكل</div>
                            <div className='totals'>مجموع الكميات: {totalQuantities} </div>
                        </div>

                        <div className='customer-information'>
                            <div>

                                <input
                                    id='customer-name'
                                    type="text"
                                    placeholder="مثل: محلات ابو عبيد"
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    required
                                />
                                <label htmlFor='customer-name'>اسم الزبون</label>
                            </div>
                            <div>
                                <input
                                    id='customer-address'
                                    type="text"
                                    placeholder="مثل: رام الله - بيتونيا"
                                    value={customerAddress}
                                    onChange={(e) => setCustomerAddress(e.target.value)}
                                    required
                                />
                                <label htmlFor='customer-address'>عنوان الزبون</label>
                            </div>
                            <div>
                                <input
                                    id='customer-phone'
                                    type="text"
                                    placeholder="مثل: 0595959159"
                                    value={customerPhone}
                                    onChange={(e) => setCustomerPhone(e.target.value)}
                                    required
                                />
                                <label htmlFor='customer-address'>رقم الهاتف</label>
                            </div>
                            {isSending && (

                                <div>
                                    <div>
                                        <spiral-loader
                                            size="40"
                                            speed="0.5"
                                            color="#bb1212"
                                        ></spiral-loader>
                                    </div>
                                    <div>جاري ارسال الطلبية</div>
                                </div>
                            )}
                        </div>
                    <button
                            type='submit'
                            id='submit-button'
                            onClick={() => submitOrder()}>ارسال الطلبية
                        </button>


                </section>


            </section>

        </div>

);

};

export default Home;
