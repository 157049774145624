import {Image, View, StyleSheet, Text} from '@react-pdf/renderer';
import React from "react";

// Define styles
const styles = StyleSheet.create({
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center children horizontally in the container
        justifyContent: 'center',
        marginTop: 20, // Adjust the space from the top of the page
        marginBottom: 20, // Adjust the space from the header or the next element
    },
    logo: {
        maxWidth: 200, // Set a maximum width for the logo
        maxHeight: 150, // Set a maximum height for the logo
        // If you know the exact dimensions of your logo, you can set them here
    },
    header: {
    padding: 10,
        flexGrow: 1,
        textAlign: 'center',
        fontSize: 18,
        fontFamily: 'BalooBhaijaan',
},
});

const Logo = () => (
    <View style={styles.logoContainer}>
        <Image
            style={styles.logo}
            src="/LogoReceipt.png" // Provide the correct path to your logo image
        />
        <Text style={styles.header}>تفصيل طلبية</Text>
    </View>
);

export default Logo;
