// PublicRoutes.js
import React from 'react';
import { Navigate } from "react-router-dom";
import AuthDetails from "./AuthDetails";

const PublicRoutes = ({ children }) => {
    const {isLoading, isAuth, email} = AuthDetails();
    // If AuthDetails is still loading
    if (isAuth === null || isLoading) {
        // Optionally render a loading indicator here
        return null;
    }

    if (!isAuth) {
        // If not authenticated, render children (SignIn component in this case)
        return children;
    } else {
        // If authenticated, redirect to /Home
        return <Navigate to="/Home" replace />;
    }
};

export default PublicRoutes;
