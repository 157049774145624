import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';

// Assuming you're using a custom font that supports Arabic
Font.register({
    family: 'BalooBhaijaan',
    src: '/fonts/BalooBhaijaan.ttf', // Make sure this path is correct
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        fontFamily: 'BalooBhaijaan',
    },
    content: {
        flexGrow: 1,
        maxHeight: 40,
        fontFamily: 'BalooBhaijaan',
        textAlign: "center",// Makes the content flexible to take up all available space
        // Add other styling as needed, ensuring there's enough space for the footer
    },
    footer: {
        marginTop: 40, // Add a bit of space above the footer
        fontFamily: 'BalooBhaijaan',
        textAlign: 'center', // For RTL support
    },

});

const Footer = () => (
    <View style={styles.footer}>
        <Text>نشكر ثقتكم</Text>
    </View>
);
export default Footer;
