// App.js
import './App.css';
import PrivateRoutes from "./components/PrivateRoutes";
import PublicRoutes from "./components/PublicRoutes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/SignIn";
import Home from "./components/Home";
import Error from "./components/Error";
import Navbar from "./components/Navbar/Navbar";
import AddUser from "./components/AddUser";
import {useEffect} from "react";
import AddProduct from "./components/AddProduct";
import ViewOrders from "./components/ViewOrders";
import {ReactNotifications} from "react-notifications-component";
import "primereact/resources/themes/mira/theme.css"
import { ConfirmDialog } from 'primereact/confirmdialog';
// import app from "firebase"

function App() {

    // const makeAPICall = async () => {
    //     try {
    //         const response = await fetch('http://localhost:8080/', {mode:'cors'});
    //         const data = await response.json();
    //         console.log({ data })
    //     }
    //     catch (e) {
    //         console.log(e)
    //     }
    // }
    // useEffect(() => {
    //     makeAPICall();
    // }, [])


    return (
        <div className='App'>
            <ReactNotifications />
            <ConfirmDialog />
            <Router>
                <Routes>
                    <Route path="/Home" element={
                        <PrivateRoutes>
                            <Navbar />
                            <Home /> {/* Wrapped Home component in PrivateRoutes */}

                        </PrivateRoutes>}
                    />
                    <Route path="/UserActions" element={
                        <PrivateRoutes>
                            <Navbar />
                            <AddUser /> {/* Wrapped Home component in PrivateRoutes */}

                        </PrivateRoutes>}
                    />
                    <Route path="/ProductActions" element={
                        <PrivateRoutes>
                            <Navbar />
                            <AddProduct /> {/* Wrapped Home component in PrivateRoutes */}


                        </PrivateRoutes>}
                    />
                    <Route path="/ViewOrders" element={
                        <PrivateRoutes>
                            <Navbar />
                            <ViewOrders /> {/* Wrapped Home component in PrivateRoutes */}

                        </PrivateRoutes>}
                    />
                    <Route path="/SignIn" element={
                        <PublicRoutes>
                            <SignIn /> {/* Wrapped SignIn component in PublicRoutes */}
                        </PublicRoutes>}
                    />
                    <Route path="*" element={<Error />} />
                </Routes>
            </Router>

        </div>
    );
}

export default App;
