import React, { useState } from "react";
import {NavLink, useNavigate} from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Navbar.css";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase/firebase-config";
import AuthDetails from "../AuthDetails";
import {FaHome, FaUser, FaUsers, FaSignOutAlt} from "react-icons/fa";
import { MdInventory } from "react-icons/md";
import {FaTableList} from "react-icons/fa6";
import {ConfirmDialog} from "primereact/confirmdialog";


const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const {isLoading, isAuth, email, displayName, role, roleNum} = AuthDetails();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1150) {
            setShowMenu(false);
        }
    };

    const handleSignOut = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            // Redirect user to SignIn page or handle the sign-out event as needed
            navigate('/SignIn');
        }).catch((error) => {
            // An error happened during sign-out
            console.error('خطأ في عملية تسجيل الخروج', error);
        });

    }
    return (
        <header className="header">
            <nav className="nav container">
                <ConfirmDialog
                    style={{width: "auto"}}
                    visible={visible} onHide={() => setVisible(false)}
                    message="سيتم الآن تسجيل خروجك"
                    header="تأكيد الخروج"
                    acceptLabel="نعم"
                    rejectLabel="لا"
                    accept={() => handleSignOut()}
                    reject={() => console.log("notLoggedOut")}
                />

                <NavLink to="/Home" className="nav__logo">
                    <img id='Logo' src='/Logo3.png' alt='Logo'/>
                </NavLink>

                <div
                    className={`nav__menu ${showMenu ? "show-menu" : ""}`}
                    id="nav-menu"
                >
                    <ul className="nav__list">
                        <li className="nav__item" onClick={() => setVisible(true)}>
                            <p className="nav__link"> تسجيل الخروج <FaSignOutAlt /></p>
                        </li>
                        {parseInt(roleNum) >= 1 && (
                        <li className="nav__item">
                        <NavLink
                                to="/ViewOrders"
                                className="nav__link"
                                onClick={closeMenuOnMobile}
                            >
                                الطلبيات <i> <FaTableList />
                        </i>
                            </NavLink>
                        </li>
                        )}
                        {parseInt(roleNum) >= 3 && (
                        <li className="nav__item">
                            <NavLink
                                to="/UserActions"
                                className="nav__link"
                                onClick={closeMenuOnMobile}
                            >
                                الموظفين<i> <FaUsers/></i>

                            </NavLink>
                        </li>
                        )}
                        {parseInt(roleNum) >= 2 && (
                        <li className="nav__item">
                            <NavLink
                                to="/ProductActions"
                                className="nav__link"
                                onClick={closeMenuOnMobile}
                            >
                                المنتجات  <i> <MdInventory/></i>
                            </NavLink>
                        </li>
                            )}
                        <li className="nav__item">
                            <NavLink
                                to="/Home"
                                className="nav__link"
                                onClick={closeMenuOnMobile}
                            >
                                الصفحة الرئيسية <i> <FaHome />
                            </i>
                            </NavLink>
                        </li>
                        <li className="nav__cta">
                            <p>{displayName} | {role} <i> <FaUser /></i></p>
                        </li>
                    </ul>
                    <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                        <IoClose />
                    </div>
                </div>

                <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                    <IoMenu />
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
// import React, {useState, useEffect} from 'react'
// import './OLDNAVBAR.css'
// import {signOut} from "firebase/auth";
// import {auth} from "../../firebase/firebase-config";
// import {useNavigate} from "react-router-dom";
// import AuthDetails from "../AuthDetails";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import { FaUser } from "react-icons/fa";
//
// export default function OLDNAVBAR() {
//     const [toggleMenu, setToggleMenu] = useState(false)
//     const [screenWidth, setScreenWidth] = useState(window.innerWidth)
//     const navigate = useNavigate();
//     const {isLoading, isAuth, email, displayName, role, roleNum} = AuthDetails();
//
//     const toggleNav = () => {
//         setToggleMenu(!toggleMenu)
//     }
//
//     const handleSignOut = () => {
//         signOut(auth).then(() => {
//             // Sign-out successful.
//             // Redirect user to SignIn page or handle the sign-out event as needed
//             navigate('/SignIn');
//         }).catch((error) => {
//             // An error happened during sign-out
//             console.error('خطأ في عملية تسجيل الخروج', error);
//         });
//
//     }
//
//     useEffect(() => {
//
//         const changeWidth = () => {
//             setScreenWidth(window.innerWidth);
//         }
//
//         window.addEventListener('resize', changeWidth)
//
//         return () => {
//             window.removeEventListener('resize', changeWidth)
//         }
//
//     }, [])
//
//     return (
//         <div className='header-container'>
//             <a><img id='Logo' src='/Logo3.png' alt='Logo'/></a>
//             <nav className='navigation'>
//                 {(toggleMenu || screenWidth > 500) && (
//                     <ul className='list'>
//                         <li className='items'><a className='link' onClick={handleSignOut}>تسجيل الخروج</a></li>
//                         {parseInt(roleNum) >= 1 && (
//                             <li className='items'><a className='link' href='/ViewOrders'>الطلبيات</a></li>
//                         )}
//                         {parseInt(roleNum) >= 3 && (
//                             <li className='items'><a className='link' href='/UserActions'>الموظفين</a></li>
//                         )}
//                         {parseInt(roleNum) >= 2 && (
//                             <li className='items'><a className='link' href='/ProductActions'>المنتجات</a></li>
//                         )}
//                         <li className='items'><a className='link' href='/Home'>الصفحة الرئيسية</a></li>
//                         <li className='usersname'>{displayName} | {role} <FaUser /> </li>
//                     </ul>
//                 )}
//
//                 <button onClick={toggleNav} className="btn">|||</button>
//             </nav>
//
//
//         </div>
//
//     )
// }