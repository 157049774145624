import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebase-config";

const AuthDetails = () => {
    const [authUser, setAuthUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [customClaims, setCustomClaims] = useState({});
    const displayName = authUser?.displayName ?? 'User';
    const [userRole, setUserRole] = useState('No role'); // Add state for userRole

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, now let's fetch their custom claims
                user.getIdTokenResult()
                    .then((idTokenResult) => {

                        setCustomClaims(idTokenResult.claims);
                        // console.log(idTokenResult.claims)

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                // No user is signed in.
                setCustomClaims({});
            }
            setAuthUser(user);
            setIsLoading(false); // Set loading to false when authentication state is resolved
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        switch(customClaims.role) {
            case "3":
                setUserRole('موظف اداري');
                break;
            case "2":
                setUserRole('موظف محل');
                break;
            case "1":
                setUserRole('مندوب مبيعات');
                break;
            default:
                setUserRole('قيمة غير معرفة');
        }
    }, [customClaims]);

    return {
        isLoading: isLoading,
        isAuth: !!authUser,
        email: authUser ? authUser.email : null,
        displayName: displayName,
        role: userRole,
        roleNum: customClaims.role,
    };
};

export default AuthDetails;

// // AuthDetails.js
// import { useEffect, useState } from "react";
// import { onAuthStateChanged } from "firebase/auth";
// import { auth } from "../firebase/firebase-config";
//
// const AuthDetails = () => {
//     const [authUser, setAuthUser] = useState(null);
//     const [isLoading, setIsLoading] = useState(true);
//     const displayName = authUser?.displayName ?? 'User';
//     // const [customClaims, setCustomClaims] = useState(null)
//     useEffect(() => {
//         const unsubscribe = onAuthStateChanged(auth, (user) => {
//             setAuthUser(user);
//             setIsLoading(false); // Set loading to false when authentication state is resolved
//         });
//
//         return () => {
//             unsubscribe();
//         };
//     }, []);
//
//     // Return loading state while authentication state is being fetched
//
//     // Return authentication status when finished loading
//     console.log(authUser);
//     // if (authUser.displayName) {
//     //
//     // }
//     return {
//         isLoading: isLoading,
//         isAuth: !!authUser,
//         email: authUser? authUser.email : null,
//         displayName: displayName,
//         // role: customClaims
// } // Convert authUser to boolean
// };
//
// export default AuthDetails;
