import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from "react";

// Register an Arabic font that supports Arabic characters
Font.register({
    family: 'BalooBhaijaan',
    src: '/fonts/BalooBhaijaan.ttf', // Make sure this path is correct
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
    },
    section: {
        // padding: 10,
        marginLeft: 68,
        // flexGrow: 1,
        textAlign: 'left',
        fontSize: 12,
        fontWeight: "extrabold",
        fontFamily: 'BalooBhaijaan', // Make sure this is the name of the font you registered
    },
    text: {
        direction: 'rtl', // Ensure text direction is right-to-left
        fontWeight: "extrabold",
    },
});

const Totals = ({ order }) => (
    <View style={styles.section}>
        <Text style={styles.text}>مجموع الاسعار - شيكل: {order.totalPrice}</Text>
        <Text style={styles.text}>مجموع الكميات: {order.totalQuantity}</Text>
    </View>
);

export default Totals;
