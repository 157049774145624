import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import AuthDetails from "./AuthDetails";

const PrivateRoutes = ({ children }) => {
    const {isLoading, isAuth, email, role, roleNum} = AuthDetails();
    const location = useLocation();
    // Check if AuthDetails is still loading
    if ((isAuth === null) || isLoading) {
        return null; // Return null to indicate loading state
    }

    // Redirect to SignIn if not authenticated
    if (!isAuth) {
        return <Navigate to="/SignIn" replace />;
    }

    // Check for role "3" to access "/addUser", use location.pathname to determine current route
    if (location.pathname === '/UserActions' && parseInt(roleNum) !== 3) {
        // Redirect users who are not of role "3" (admin) to the homepage or another appropriate route
        console.log(role)
        return <Navigate to="/Home" replace />;
    }
    if (location.pathname === '/ProductActions' && parseInt(roleNum) === 1) {
        // Redirect users who are not of role "3" (admin) to the homepage or another appropriate route
        console.log(role)
        return <Navigate to="/Home" replace />;
    }

    // If authenticated and authorized, render children
    return children;
};


export default PrivateRoutes;
